import React from 'react';
import UncontrolledTooltip from '../../components/reactstrap/UncontrolledTooltip';
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import { t } from 'i18next';
import { Button } from 'reactstrap';
import { ButtonIcon } from '../buttons';

class TaskActions extends React.Component {
    render() {
        const { id, data } = this.props;

        return (
            <React.Fragment>
                {data.payment.applicable && (
                    <ButtonIcon id={'payment-' + id} size="sm" icon="euro-sign" className={'text-' + t('settings.payment:status:color:' + data.payment.status)} tooltip="Online betaling" onClick={() => this.props.onPaymentModalClick(id, data.payment)} />
                )}

                {data.patientId && (
                    <ButtonIcon id={'mail' + id} size="sm" icon="envelope" onClick={() => this.props.history.push(reverse(routes.patients.mail, { id: data.patientId }))} tooltip={t('patients.patient:action-sendmail')} />
                )}

                <ButtonIcon id={'details' + id} size="sm" icon="eye" onClick={() => this.props.onDetailClick(id)} tooltip={t('tools.task:modal:title')} />

                {/*!data.watching ?
                    (<>
                        <Button color="default" size="sm" id={'buttonWatch' + id} onClick={() => this.props.onDetailClick(id)}>
                            <i className="fas fa-eye" aria-hidden></i>
                        </Button>
                        <UncontrolledTooltip target={'buttonWatch' + id}>
                            {t('common:action-watch')}
                        </UncontrolledTooltip>
                    </>) :
                    (<>
                        <Button color="default" size="sm" id={'buttonWatch' + id} onClick={() => this.props.toggleWatcher(id)}>
                            <i className="fas fa-eye-slash" aria-hidden></i>
                        </Button>
                        <UncontrolledTooltip target={'buttonWatch' + id}>
                            {t('common:action-unwatch')}
                        </UncontrolledTooltip>
                    </>)
                    */}

                <ButtonIcon id={'logs' + id} size="sm" icon="list" onClick={() => this.props.onLogClick(id)} tooltip="Logs" />

                <Button color="info" size="sm" id={'buttonEdit' + id} onClick={() => this.props.history.push(reverse(routes.communication.tasks.edit, { id: id }))}>
                    <i className="fas fa-pencil-alt" aria-hidden></i>
                </Button>
                <UncontrolledTooltip target={'buttonEdit' + id}>
                    {t('common:action-edit')}
                </UncontrolledTooltip>

                <Button color="danger" size="sm" id={'buttonRemove' + id} onClick={() => this.props.remove(id)}>
                    <i className="fas fa-trash" aria-hidden></i>
                </Button>
                <UncontrolledTooltip target={'buttonRemove' + id}>
                    {t('common:action-remove')}
                </UncontrolledTooltip>
            </React.Fragment >
        );
    }
}

export default TaskActions;