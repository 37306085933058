import React from 'react';

const NotificationIcon = props => (
    <div className={'notification notification-outline-' + props.color}>
        <span className={'mr-2 fa fa-' + props.icon}></span>
        {props.message}
    </div>
)

NotificationIcon.defaults = {
    color: 'info'
}

export default NotificationIcon;