import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import nl from './Tel/nl';

class TelField extends React.Component {
    render() {
        let { name, data, readonly } = this.props.field;

        return (
            <PhoneInput
                country="BE"
                placeholder={'Geef tel nummer'}
                value={data ? data : ''}
                onChange={this.onChange}
                readOnly={readonly}
                name={name}
                id={name}
                labels={nl}
                flags={flags}
            />
        );
    }

    onChange = (value) => {
        const { name } = this.props.field;

        this.props.onChangeValue(name, value);
    }
}

export default TelField;