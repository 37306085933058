import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';
import { ApiCommonService } from '../../services/api/common.service';
import { UtilNotification } from '../../utils/notification.util.js';

class ModalClone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            request: {
                url: props.cloneUrl,
                params: {},
                redirectUrl: props.redirectUrl,
                successMessage: props.successMessage,
                actionAfterRemove: props.actionAfterRemove
            },
            options: {
                title: props.title ? props.title : null,
                text: props.text ? props.text : null,
                buttons: [
                    {
                        text: t('common:Cancel'),
                        color: 'default',
                        type: 'cancel'
                    },
                    {
                        text: t('common:Confirm'),
                        color: 'info',
                        type: 'confirm'
                    }
                ]
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        return (
            <Modal isOpen={this.state.open}>
                {this.state.options.title && <ModalHeader>{this.state.options.title}</ModalHeader>}
                {this.state.options.text && <ModalBody>{this.state.options.text}</ModalBody>}

                <ModalFooter>
                    {this.state.options.buttons.map(btn => <Button key={btn.text} color={btn.color} onClick={() => this.handle(btn.type)}>{btn.text}</Button>)}
                </ModalFooter>
            </Modal>
        );
    }

    fire = (params, request = {}) => {
        let stateRequest = this.state.request;
        stateRequest = Object.assign(stateRequest, request);
        stateRequest = {
            ...stateRequest,
            params: params
        }

        this.setState({
            request: stateRequest,
            open: true
        });
    };

    handle = (e) => {
        switch (e) {
            case 'confirm':
                this.cloneItem();
                break;
            default:
                break;
        }

        this.setState({ open: false });
    }

    cloneItem = () => ApiCommonService.getItem(this.state.request.url, this.state.request.params)
        .then(result => {
            if (this.state.request.actionAfterRemove) {
                this.state.request.actionAfterRemove(this.state.request.params.id);
            }
            UtilNotification.success(this.state.request.successMessage);
        })
        .catch(error => UtilNotification.error(error))
        .then(() => {
            this.props.history ? this.props.history.push(this.state.request.redirectUrl) : window.location = this.state.request.redirectUrl;
        });
}

export default ModalClone;