const chunk = (arr, size) => [...Array(Math.ceil(arr.length / size))].map((value, index) => arr.slice(index * size, (index + 1) * size))

// Sort object by keys - https://www.codegrepper.com/code-examples/typescript/sort+javascript+object+by+key
const ksort = (object) => Object.keys(object).sort().reduce((obj, key) => {
    obj[key] = object[key];
    return obj;
}, {});

// Sort object by keys
const sort = (object, direction = 'asc') => Object.entries(object).sort(([, a], [, b]) => (direction === 'asc' ? a > b : b > a) ? 1 : -1).reduce((r, [k, v]) => ({ ...r, [' ' + k]: v }), {});

// Sort object by value
const asort = (object, key, direction = 'asc') => Object.values(object).sort((a, b) => direction === 'asc' ? a[key] - b[key] : b[key] - a[key]); //.reduce((r, [k, v]) => ({ ...r, [' ' + k]: v }), {});


const inArray = (array, needle) => {
    if (!Array.isArray(needle)) needle = [needle];

    let inArray = false;
    needle.forEach(element => {
        // if (array.indexOf(element) > -1) inArray = true;
        if (array.findIndex(item => String(item) === String(element)) > -1) inArray = true;
    });

    return inArray;
}

const range = (number, start = 1, step = 1) => Array.from({ length: (number - start) / step + 1 }, (_, i) => start + i * step);

export const UtilArray = {
    chunk,
    ksort,
    sort,
    asort,

    inArray,

    range
};