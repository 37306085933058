import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { ApiWorkspaceService } from '../../services/api/workspace.service';
import { ApiClientService } from '../../services/api/index';

class DropdownWorkspaces extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            client: props.group.clients.find(client => client.id === props.client.id),
            open: false,
            selected: props.selected,
            items: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.load();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.items.length <= 0) return null;

        return (
            <UncontrolledButtonDropdown isOpen={this.state.open} toggle={() => this.setState({ open: !this.state.open })} className="d-inline-block mr-md-2" id="btn-workspace-select">
                <DropdownToggle caret color="default" className="btn-labeled">
                    <span className="btn-label"><em className="fa fa-building"></em></span>
                    <span className="ml-1">{this.state.selected ? this.state.selected.title : t('common:None')}</span>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this.onChange(null)}>
                        <div className="pl-1">{t('common:None')}</div>
                    </DropdownItem >
                    <DropdownItem divider />
                    {this.state.items.map(item => (
                        <DropdownItem key={item.id} onClick={() => this.onChange(item)} active={this.stateselected && this.state.selected.id === item.id}>
                            {item.title}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }

    onChange = (item) => ApiClientService.updateSetting(this.props.client.id, 'agendaSelectedWorkspace', item ? item.id : null)
        .then(result => {
            if (!result) return;

            let group = this.props.group;

            group.clients.forEach(client => {
                if (client.settings.id === result.id) {
                    client.settings = result;
                }
            });
            this.props.actions.groupUpdate(group);
            this.setState({ selected: item });
        });

    load = () => ApiWorkspaceService.getByGroup(this.props.group.id)
        .then(data => this.setState({ items: data, selected: data.find(item => item.id === this.state.client.settings.agendaSelectedWorkspace) }))
        .catch(error => console.error(error));
}

const mapStateToProps = state => ({ group: state.group ? state.group : null });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(DropdownWorkspaces);