import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { bindActionCreators } from 'redux';
import { ApiGroupService } from '../../services/api/group.service';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { Icon } from '../icons';

class DropdownPhoneDevices extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            open: false,
            devices: [],
            connectedPhoneNumber: null
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.loadDevices();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (!this.state.devices || this.state.devices.length <= 0) return null;

        let { phoneSocket } = this.props.settings;

        let connected = phoneSocket && phoneSocket.connected;

        let activeDevice = null;
        if (phoneSocket && phoneSocket.selectedDevice) {
            this.state.devices.forEach(device => {
                if (device.extension === phoneSocket.selectedDevice) {
                    activeDevice = device;
                }
            })
        }

        return (
            <UncontrolledButtonDropdown isOpen={this.state.open} toggle={() => this.setState({ open: !this.state.open })} className="d-inline-block mr-md-2" id="btn-phone-device-select">
                <DropdownToggle caret color="default" className="btn-labeled">
                    <span className='btn-label'><Icon icon="fa fa-phone" /></span>
                    <span className="ml-1">{activeDevice ? activeDevice.extension + ' - ' + activeDevice.info : t('common:None')}</span>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this.onDeviceChange({ extension: null })}>
                        <div className="pl-1">{t('common:None')}</div>
                    </DropdownItem >
                    <DropdownItem divider />

                    {this.state.devices.map(device => (
                        <DropdownItem key={device.extension} onClick={() => this.onDeviceChange(device)} active={activeDevice && activeDevice.extension === device.extension}>
                            {device.extension + ' - ' + device.info}
                        </DropdownItem>
                    ))}

                    <DropdownItem divider />
                    <DropdownItem tag="div" toggle={false}>
                        {connected ?
                            <><span className="fa fa-check text-success"></span> Geconnecteerd</> :
                            <><span className="fa fa-times text-danger"></span> Niet geconnecteerd</>
                        }
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }

    onDeviceChange = (device) => {
        let { phoneSocket } = this.props.settings;

        if (!phoneSocket) {
            phoneSocket = {
                selectedDevice: null,
                connectedPhoneNumber: null
            }
        }

        if (phoneSocket.selectedDevice === device.extension) return;

        phoneSocket.selectedDevice = device.extension;
        this.props.actions.changeSetting('phoneSocket', phoneSocket);

        if (!device.extension) {
            this.reset();
            if (this.props.onClear) {
                this.props.onClear();
            }
        }
    }

    loadDevices = () => ApiGroupService.getPhoneDevices(this.props.group.id)
        .then(data => {
            let { devices, socket } = data;

            this.setState({ devices: devices })

            if (devices && devices.length > 0) {
                this.connect(socket);
            }
        })
        .catch(() => { })

    reset = () => {
        this.props.actions.setPatient(null);
        this.props.actions.changeSetting('caller', {
            id: null,
            results: []
        });
    }
}

const mapStateToProps = state => ({ group: state.group ? state.group : null, settings: state.settings ? state.settings : {} });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(DropdownPhoneDevices);