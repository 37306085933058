import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes from '../../routing/routes';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}

            render={props =>
                rest.client ?
                    <Component {...props} /> :
                    <Redirect to={{ pathname: routes.auth.login, state: { from: props.location } }} />
            }
        />
    )
};

const mapStateToProps = state => ({ client: state.client ? state.client.active : null });

export default connect(mapStateToProps)(PrivateRoute);