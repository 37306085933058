import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { Button, Modal, ModalFooter, Table, Col, Row } from 'reactstrap';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { UtilDate } from '../../utils/date.util';
import { BadgeCategory, BadgeStatus, BadgeSubcategory } from '../badge';
import moment from 'moment';
import { ButtonActionCustom } from '../buttons';
import { ModalPayment, ModalBody } from '.';
import { ApiAppointmentService } from '../../services/api/index';

class ModalAppointmentsOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            modal: true,
            nestedPaymentModal: false,
            appointments: []
        }
    }

    componentDidMount = () => {
        this.props.onModalRef(this);
        this.load();
    };

    componentWillReceiveProps = () => this.setState({ modal: true }, () => this.load());
    componentWillUnmount = () => this.props.onModalRef(null);

    render() {
        const { appointments } = this.state;
        const { url, start, end } = this.props.event;
        const { category, subcategory, maximumAppointments } = this.props.event.extendedProps;

        let maximumAppointmentsReached = true;
        if (maximumAppointments && appointments.length < maximumAppointments) {
            maximumAppointmentsReached = false;
        }

        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <Row className="modal-header">
                    <Col lg={8} md={12}>
                        <strong className="modal-title align-middle">
                            {t('agenda.myagenda:appointmentModal:title')}
                            <span className="ml-1">[{appointments.length}/{maximumAppointments}]</span>
                        </strong>
                        <br />
                        <BadgeCategory className="mr-2" id={category} />
                        {subcategory && <BadgeSubcategory className="mr-2" id={subcategory} />}
                    </Col>

                    <Col lg={4} md={12}>
                        {t('agenda.appointment:date')}: <strong>{UtilDate.format(start, 'DD/MM/YYYY')}</strong><br />
                        {t('agenda.appointment:from')}: <strong>{UtilDate.format(start, 'HH:mm')} - {UtilDate.format(end, 'HH:mm')}</strong><br />
                    </Col>

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>
                <ModalBody loading={this.state.loading}>
                    <Table className="table-striped table-responsive mr-5">
                        <tbody>
                            {appointments.map(item => (
                                <tr key={item.id}>
                                    <td style={{ width: '100%' }}>
                                        <strong>{item.patient.name} {item.repeatSequence && <span>[{item.repeatSequence}]</span>}</strong>
                                        {item.appointmentStatus && <BadgeStatus id={item.appointmentStatus} className="ml-2" />}
                                    </td>
                                    <td className="table-actions">
                                        {item.payment.applicable && (
                                            <ButtonActionCustom id={'payment-' + item.id} color="default" icon="euro-sign" className={'text-' + t('settings.payment:status:color:' + item.payment.status)} tooltip="Online betaling" onClick={() => this.onAppointmentPaymentModalClick(item)} />
                                        )}
                                        <ButtonActionCustom id={'details-' + item.id} color="default" icon="info-circle" onClick={() => this.props.onDetailClick(this.props.event, item, this.props.appointments)} tooltip={t('common:action-detail')} />
                                        <ButtonActionCustom id={'edit-' + item.id} icon="pencil-alt" onClick={() => this.onEditClick(item)} tooltip={t('common:action-edit')} />
                                        <ButtonActionCustom id={'remove-' + item.id} icon="trash" color="danger" onClick={() => this.onRemoveClick(item)} tooltip={t('common:action-remove')} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                {!maximumAppointmentsReached && url && (
                    <ModalFooter>
                        <div className="modal-buttons">
                            <Button color="success" className="btn-labeled btn-add" onClick={this.onAddClick}>
                                <span className="btn-label"><i className="fa fa-plus" aria-hidden></i></span>
                                <div className="btn-text">{t('common:Add')}</div>
                            </Button>
                        </div>
                    </ModalFooter>
                )}
            </Modal >
        );
    }

    renderAppointmentRow = (item) => (
        <tr key={item.id}>
            <td style={{ width: '100%' }}>
                <strong>{item.patient.name} {item.repeatSequence && <span>[{item.repeatSequence}]</span>}</strong>
                {item.appointmentStatus && <BadgeStatus id={item.appointmentStatus} className="ml-2" />}
            </td>
            <td className="table-actions">
                {item.payment.applicable && (
                    <ButtonActionCustom id={'payment-' + item.id} color="default" icon="euro-sign" className={'text-' + t('settings.payment:status:color:' + item.payment.status)} tooltip="Online betaling" onClick={() => this.onAppointmentPaymentModalClick(item)} />
                )}
                <ButtonActionCustom id={'details-' + item.id} color="default" icon="info-circle" onClick={() => this.props.onDetailClick(this.props.event, item, this.props.appointments)} tooltip={t('common:action-detail')} />
                <ButtonActionCustom id={'edit-' + item.id} icon="pencil-alt" onClick={() => this.onEditClick(item)} tooltip={t('common:action-edit')} />
                <ButtonActionCustom id={'remove-' + item.id} icon="trash" color="danger" onClick={() => this.onRemoveClick(item)} tooltip={t('common:action-remove')} />
            </td>
        </tr>
    )

    load = () => this.setState({ loading: true }, () =>
        ApiAppointmentService.getByIds(this.props.appointments.map(appointment => appointment.id))
            .then(items => this.setState({ loading: false, appointments: items })));

    onAddClick = () => {
        const { start, end } = this.props.event;
        const { resourceId, isHomeVisit, category } = this.props.event.extendedProps;

        this.onToggle();

        if (isHomeVisit) {
            this.props.history.push(reverse(routes.homevisits.add, { date: moment(start).format('YYYY-MM-DD'), client: resourceId, time: moment(start).format('HH:mm'), category: category }));
        } else {
            this.props.history.push(reverse(routes.appointments.add, { client: resourceId, start: UtilDate.format(start, 'YYYYMMDDHHmm'), end: moment(end).format('YYYYMMDDHHmm'), category: category }));
        }
    }

    onEditClick = (item) => {
        this.onToggle();

        if (item.isHomeVisit) {
            this.props.history.push(reverse(routes.homevisits.edit, { id: item.id, client: item.clientId }));
        } else {
            this.props.history.push(reverse(routes.appointments.edit, { id: item.id, client: item.clientId }));
        }
    }

    onRemoveClick = (item) => {
        this.onToggle();
        this.props.modalRemove.fire({ id: item.id })
    }

    onToggle = () => this.setState({ modal: !this.state.modal });

    // Payment modal
    onAppointmentPaymentModalClick = appointment => this.setState({ nestedPaymentModal: true }, () => ReactDOM.render(<ModalPayment appointment={appointment} onToggle={this.toggleNestedPayment} />, document.getElementById('modal-section')));
    toggleNestedPayment = (reload) => reload ? this.load() : null;
}

export default ModalAppointmentsOverview;