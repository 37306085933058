import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { Table, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { TableHead, TableBody } from '../../../../components/table';
import routes from '../../../../routing/routes';
import { reverse } from 'named-urls';
import moment from 'moment';
import { ApiAppointmentService } from '../../../../services/api/appointment.service';
import { BadgeCategory, BadgeStatus, BadgeSubcategory } from '../../../../components/badge';
import { ButtonActionCustom, ButtonActionRemove, ButtonIcon } from '../../../../components/buttons';
import { ModalAppointment, ModalAppointmentCancel, ModalAppointmentLog, ModalRemove } from '../../../../components/modals';
import { UtilNotification } from '../../../../utils';

class PatientAppointment extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.modalRemoveProps = {
            ...props,
            removeUrl: 'client/appointment/appointment/remove-appointment', // API endpoint URL
            // redirectUrl: reverse(routes.patients.appointments, { id: props.patientId }),
            onFinish: this.loadItems,
            successMessage: t('agenda.appointment:remove.successfull'),
            text: t('agenda.appointment:remove.message'),
            onRef: (ref) => this._modalRemove = ref
        }

        this.state = {
            items: [],
            loading: false,
            activeTab: 'upcoming'
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this._isMounted = true;

        this.loadItems();
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Nav tabs justified>
                    {/* UPCOMING */}
                    {this.renderNavItem('upcoming')}

                    {/* PAST */}
                    {this.renderNavItem('past')}

                    {/* DELETED */}
                    {this.renderNavItem('deleted')}
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="bg-white p-0">
                    {/* UPCOMING */}
                    <TabPane tabId="upcoming">
                        {this.renderTable('upcoming')}
                    </TabPane>

                    {/* PAST */}
                    <TabPane tabId="past">
                        {this.renderTable('past')}
                    </TabPane>

                    {/* DELETED */}
                    <TabPane tabId="deleted">
                        {this.renderTable('deleted')}
                    </TabPane>
                </TabContent>

                <ModalRemove {...this.modalRemoveProps} />

                <ModalAppointmentCancel
                    removeUrl={'client/appointment/appointment/remove-appointment'} // API endpoint URL
                    removeRepeatedUrl={'client/appointment/appointment/remove-repeated-appointment'} // API endpoint URL
                    // redirectUrl={reverse(routes.patients.appointments, { id: })}
                    onFinish={this.loadItems}
                    successMessage={t('agenda.appointment:cancel.successfull')}
                    text={t('agenda.appointment:cancel.message')}
                    onRef={ref => (this._modalAppointmentCancel = ref)}
                    history={this.props.history}
                />

            </React.Fragment>
        );
    }

    renderNavItem = (type) => {
        let icon = 'fa-history fa-flip-horizontal';
        if (type === 'past') {
            icon = 'fa-history';
        } else if (type === 'deleted') {
            icon = 'fa-trash';
        }

        return (
            <NavItem>
                <NavLink
                    className={this.state.activeTab === type ? 'active' : ''}
                    onClick={() => { this.toggleTab(type); }}
                >
                    <span className={'mr-2 fa ' + icon}></span>
                    {t('patients.patient:appointments:' + type)}
                </NavLink>
            </NavItem>
        );
    }

    renderTable = (tab) => {
        return (
            <Table striped bordered responsive>
                <TableHead>
                    <tr>
                        <th width="150">{t('patients.patient:appointments:table:date')}</th>
                        <th width="100">{t('patients.patient:appointments:table:time')}</th>
                        <th width="75" className="text-center">{t('patients.patient:appointments:table:repeating')}</th>
                        <th className="text-nowrap">{t('patients.patient:appointments:table:client')}</th>
                        <th width="200" className="text-center">{t('patients.patient:appointments:table:category')}</th>
                        <th width="200" className="text-center">{t('patients.patient:appointments:table:subcategory')}</th>
                        <th width="200" className="text-center">{t('patients.patient:appointments:table:status')}</th>
                        {tab === 'deleted' && <th className="text-nowrap">{t('patients.patient:appointments:table:cancel-reason')}</th>}
                        {this.props.showActions && <th>{t('common:actions')}</th>}
                    </tr>
                </TableHead>
                <TableBody loading={this.state.loading} cols={tab === 'deleted' ? 9 : 8}>
                    {this.state.items.map(item => this.renderTableRow(item, tab))}
                </TableBody>
            </Table>
        );
    }

    renderTableRow(item, tab) {
        let status = null;
        if (item.appointmentStatus && this.props.schemaData.statuses[item.appointmentStatus] !== undefined) {
            status = this.props.schemaData.statuses[item.appointmentStatus];
        }

        return (
            <tr key={item.id}>
                <td>{moment(item.start).format('ddd DD/MM/YYYY')}</td>
                <td>{moment(item.start).format('HH:mm')}</td>
                <td className="text-center">{item.repeatSequence ? item.repeatSequence : '-'}</td>
                <td>{item.client}</td>
                <td className="text-center">
                    <BadgeCategory id={item.categoryId} title={item.category} fontSize="0.85rem" fontWeight="normal" />
                </td>
                <td className="text-center">
                    {item.subcategoryId ? <BadgeSubcategory id={item.subcategoryId} title={item.subcategory} fontSize="0.85rem" fontWeight="normal" /> : '-'}
                </td>
                <td className="text-center">
                    {status ? <BadgeStatus id={status.id} title={status.title} fontSize="0.85rem" fontWeight="normal" /> : '-'}
                </td>
                {tab === 'deleted' && <td className="text-nowrap">{item.canceledReason}</td>}
                {this.props.showActions && <td className="table-actions">
                    <ButtonIcon icon="list" id={'logs' + item.id} onClick={() => this.onStatusLogClick(item.id)} size="sm" tooltip="Logs" />

                    {this.state.activeTab !== 'deleted' &&
                        <>
                            <ButtonIcon icon="eye" id={'appointmentModal' + item.id} onClick={() => this.renderAppointmentModal(item)} size="sm" tooltip={t('agenda.myagenda:appointmentModal:title')} />
                            <ButtonActionCustom id={'edit-' + item.id} icon="pencil-alt" onClick={() => this.onEditClick(item)} tooltip={t('common:action-edit')} />
                            <ButtonActionRemove id={item.id} modalRemove={this._modalRemove} />
                        </>
                    }
                </td>}
            </tr>
        );
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab }, () => this.loadItems(tab));
        }
    }

    onStatusLogClick = (appointmentId) => ReactDOM.render(<ModalAppointmentLog appointmentId={appointmentId} schemaData={this.props.schemaData} />, document.getElementById('modal-section'));

    loadItems = (type = null) => {
        if (!type) type = this.state.activeTab;

        this.setState({ loading: true, items: [] }, () => ApiAppointmentService.getByPatient(this.props.patientId, this.props.group.id, type)
            .then(results => this.setState({ items: results, loading: false }))
            .catch(error => {
                UtilNotification.toastError();
                this.props.history.push(reverse(routes.patients.overview));
            })
        );
    }

    onEditClick = (item) => {
        if (this.props.toggleModals) {
            this.props.toggleModals();
        }

        let url = item.isHomeVisit ? reverse(routes.homevisits.edit, { id: item.id }) : reverse(routes.appointments.edit, { id: item.id, client: item.clientId });
        this.props.history.push(url);
    }

    renderAppointmentModal = (appointment) => {
        let client = this.props.group.clients.filter(client => client.id === appointment.clientId);
        client = client.length > 0 ? client[0] : {};

        ReactDOM.render(
            <ModalAppointment {...this.props}
                appointment={appointment}
                removeModal={this._modalRemove}
                modalAppointmentCancel={this._modalAppointmentCancel}
                schemaData={this.props.schemaData}
                calendarRef={this.calendarRef}
                load={this.loadItems}
                onModalRef={ref => (this._modalAppointment = ref)} {...this.props}
                showCancelButton={client.settings ? client.settings.cancelAppointmentsWithReason : false}
            />, document.getElementById('modal-section'));
    }
}

export default PatientAppointment;