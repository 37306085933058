import React from 'react';
import { Col, Row } from 'reactstrap';

const ModalHeader = props => (
    <Row className={'modal-header ' + props.className}>
        <Col md={12} className="d-flex pr-5">
            <div className="modal-title">
                <strong>{props.title}</strong>
                {props.text && <p className='m-0'>{props.text}</p>}
            </div>

            {props.actions && <div className='ml-auto'>{props.actions}</div>}
        </Col>

        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.onClose}>
            <span aria-hidden="true">&times;</span>
        </button>
    </Row>
)

export default ModalHeader;