import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';

class ModalConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            data: null,
            options: {
                title: props.title ? props.title : null,
                text: props.text ? props.text : null,
                buttons: props.buttons ? props.buttons : [
                    {
                        text: t('common:Cancel'),
                        color: 'default',
                        type: 'cancel'
                    },
                    {
                        text: t('common:Confirm'),
                        color: 'info',
                        type: 'confirm'
                    }
                ]
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { open, options } = this.state;

        let modalHeader, modalBody;

        if (options.title) {
            modalHeader = (
                <ModalHeader>{options.title}</ModalHeader>
            );
        }

        if (options.text) {
            modalBody = (
                <ModalBody>{options.text}</ModalBody>
            );
        }

        return (
            <Modal isOpen={open}>
                {modalHeader}
                {modalBody}

                <ModalFooter>
                    {options.buttons.map((btn, i) => {
                        return <Button key={i} color={btn.color} onClick={() => this.handle(btn.type)}>{btn.text}</Button>
                    })}
                </ModalFooter>
            </Modal>
        );
    }

    fire = (data) => this.setState({ data: data, open: true });

    handle = (e) => {
        switch (e) {
            case 'confirm':
                this.confirm();
                break;
            default:
                this.cancel();
        }

        this.setState({ open: false });
    }

    confirm = () => this.props.onConfirm('confirmed', this.state.data);
    cancel = () => this.props.onCancel('canceled', this.state.data);
}

export default ModalConfirm;