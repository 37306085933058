import React from 'react';
import { t } from 'i18next';
import { Table } from 'reactstrap';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { ApiPatientService } from '../../services/api/patient.service';
import { UtilDate } from '../../utils/date.util';
import swal from 'sweetalert';
import { UtilNotification } from '../../utils';
import { ButtonActionCustom } from '../buttons';

class TablePatientStatuses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showOnEmpty: props.showOnEmpty ? true : false,
            patientId: props.patientId,
            counters: props.statusCounters ? props.statusCounters : [],
            disabled: false,
            resetSwalOptions: {
                title: t('patients.patient:swal.reset:title'),
                text: t('patients.patient:swal.reset:text'),
                buttons: {
                    cancel: {
                        text: t('common:No'),
                        value: false,
                        visible: true,
                        className: 'bg-danger',
                        closeModal: true
                    },
                    confirm: {
                        text: t('common:Yes'),
                        value: true,
                        visible: true,
                        className: 'bg-gray-lighter',
                        closeModal: true
                    }
                }
            }
        };
    }

    render() {
        if (!this.state.showOnEmpty && this.state.counters.length <= 0) return null;

        return (
            <Table striped bordered responsive size="sm">
                <TableHead>
                    <tr>
                        <th colSpan={4} className='text-center'>{t('patients.patient:status.title')}</th>
                    </tr>
                    <tr>
                        <th>{t('patients.patient:status.status')}</th>
                        <th>{t('patients.patient:status.count')}</th>
                        <th>{t('patients.patient:status.dateLastAssigned')}</th>
                        <th>&nbsp;</th>
                    </tr>
                </TableHead>
                <TableBody cols={4}>
                    {this.state.counters.map((item, index) => (
                        <tr key={index}>
                            <td>{item.status}</td>
                            <td className='text-center'>{item.count}</td>
                            <td>{item.dateLastAssigned ? UtilDate.create(item.dateLastAssigned).format('DD/MM/YYYY HH:mm') : '-'}</td>
                            <td className="text-right">
                                <ButtonActionCustom id={'status-decrease-' + item.id} color="default" size="xs" icon="minus" className="mr-1" disabled={this.state.disabled} tooltip={t('patients.patient:decreaseStatus')} onClick={() => this.onResetClick('decrease', item)} />
                                <ButtonActionCustom id={'status-increase-' + item.id} color="success" size="xs" icon="plus" className="mr-1" disabled={this.state.disabled} tooltip={t('patients.patient:increaseStatus')} onClick={() => this.onResetClick('increase', item)} />
                                <ButtonActionCustom id={'status-reset-' + item.id} color="danger" size="xs" icon="undo" className="mr-1" disabled={this.state.disabled} tooltip={t('patients.patient:resetStatus')} onClick={() => this.onResetClick('reset', item)} />
                            </td>
                        </tr>
                    ))}
                </TableBody>
            </Table>
        );
    }

    onResetClick = (action, item) => this.setState({ disabled: true }, () => {
        if (
            (action === 'reset' && item.unblockOnReset) ||
            (action === 'decrease' && item.unblockOnDecrease)
        ) {
            swal(this.state.resetSwalOptions).then(p => this.handleStatusReset(action, item.id, p));
        } else {
            this.handleStatusReset(action, item.id, false);
        }
    });

    handleStatusReset = (action, statusId, unblockPatient) => ApiPatientService.resetStatus({ patientId: this.state.patientId, action: action, id: statusId, unblockPatient: unblockPatient })
        .then(result => {
            UtilNotification.toastSuccess();

            if (unblockPatient && !result.blocked) {
                let el = document.getElementById('patient-field-block-reason');
                if (el) el.classList.add('d-none');
            }

            if (this.props.onReset) {
                this.props.onReset();
            }

            this.setState({ counters: result.status_counters });
        })
        .then(() => this.setState({ disabled: false }));
}

export default TablePatientStatuses;