import { Button } from 'reactstrap';
import React from 'react';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonIcon = props => (
    <>
        <Button color={props.color} size={props.size} className={props.className} id={'buttonIcon' + props.id} onClick={props.onClick}>
            <span className={'fa fas far fa-' + props.icon} aria-hidden></span>
            {props.children}
        </Button>
        {props.tooltip && (
            <UncontrolledTooltip target={'buttonIcon' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </>
)

ButtonIcon.defaultProps = {
    color: 'default',
    size: 'md'
}

export default ButtonIcon;