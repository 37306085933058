import React from 'react';
import { Button } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionCustom = props => (
    <React.Fragment>
        <Button color={props.color} size={props.size} id={'buttonCustom' + props.id} onClick={props.onClick} className={props.className} disabled={props.disabled}>
            <i className={'fas fa-' + props.icon} aria-hidden></i>
            {props.children ? <span className="ml-1">{props.children}</span> : null}
        </Button>
        {props.tooltip && (
            <UncontrolledTooltip placement={props.placement} target={'buttonCustom' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </React.Fragment>
)

ButtonActionCustom.defaultProps = {
    color: 'info',
    size: 'sm',
    placement: 'top'
};

export default ButtonActionCustom;