import { UtilArray } from "../../utils";
import Client from "../Client/Client";
import Group from "./Group";

export default class Subgroup {
    id;
    name;
    position;

    group;

    clients = [];

    static transform = (data, referer = []) => {
        let item = new Subgroup();

        item.id = data.id;
        item.position = data.position;

        item.name = data.name;

        if (!UtilArray.inArray(referer, 'group')) {
            item.group = Group.transform(data.group, ['subgroup']);
        }

        if (!UtilArray.inArray(referer, 'client')) {
            item.clients = data.clients.map(client => Client.transform(client, ['subgroup']));
        }

        return item;
    }

    getClient = (id) => this.clients.find(client => client.id === id);
}