import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import { ModalBody } from '..';

class ModalPaymentLog extends React.Component {
    constructor(props) {
        super(props);

        this._columns = [{
            key: 'date',
            name: t('settings.payment:modal.logs:date'),
            width: 150,
            resizable: true
        },
        {
            key: 'type',
            name: t('settings.payment:modal.logs:action'),
            width: 150,
            resizable: true
        },
        {
            key: 'assigned',
            name: t('settings.payment:modal.logs:assigned'),
            width: 200,
            resizable: true
        },
        {
            key: 'message',
            name: t('settings.payment:modal.logs:message'),
            resizable: true
        }];

        this.state = {
            modal: true,
            rows: this.createRows(props.payment.logs)
        };
    }

    componentWillReceiveProps = (newProps) => this.setState({ rows: this.createRows(newProps.payment.logs) }, () => this.fire());

    fire = () => this.setState({ modal: true });
    onToggle = () => this.setState({ modal: !this.state.modal });

    createRows = (logs) => logs.map(log => {
        let message = log.value;
        if (log.type === 'status') {
            message = t('settings.payment:status:label:' + log.value);
        }

        return {
            date: log.timestamp,
            type: t('settings.payment:modal.logs:actions:' + log.type),
            assigned: log.by,
            message: message
        }
    });

    render = () => (
        <Modal className="statuslogs" size="md" isOpen={this.state.modal} toggle={this.onToggle}>
            <ModalHeader><strong>{t('settings.payment:modal.logs:title')}</strong></ModalHeader>
            <ModalBody loading={this.state.loading}>
                <div style={{ maxWidth: '100%' }}>
                    <ReactDataGrid
                        columns={this._columns}
                        rowGetter={(i) => this.state.rows[i]}
                        rowsCount={this.state.rows.length}
                        minHeight={250}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="info" onClick={this.onToggle}>{t('common:Close')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalPaymentLog;