import { APPLICATION_UPDATE } from './application.actions';

const initialApplication = {
    version: '1.0.0'
};

const applicationReducer = (state = initialApplication, action) => {
    switch (action.type) {
        case APPLICATION_UPDATE:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}

export default applicationReducer;