import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import { reverse } from 'named-urls';
import routes from '../../../routing/routes';
import TablePatientStatuses from '../../table/TablePatientStatuses';
import { ApiPatientService } from '../../../services/api/patient.service';
import { BadgeTag } from '../../badge';
import moment from 'moment';
import { UtilEvent } from '../../../utils';
import { NotificationIcon, NotificationWarning } from '../../notification/index';

class PatientField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            patient: {},
            patientName: null
        }
    }

    componentDidMount() {
        this.assemblePatientName();
    }

    componentDidUpdate() {
        let el = document.getElementById(this.props.field.name);
        if (!el) return null;

        if (!el.dataset.statusCounters) return null;

        let additionalDataEl = document.getElementById('appointment-left-additional-data');
        if (!additionalDataEl) return;

        ReactDOM.render(<TablePatientStatuses patientId={el.dataset.id} statusCounters={JSON.parse(el.dataset.statusCounters)} />, additionalDataEl);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.patient.id && (<Label className="col-form-label" for={this.props.field.name}>Patiënt</Label>)}
                <Input type="text" name={this.props.field.name} id={this.props.field.name} style={{ display: 'none' }}
                    onChange={this.handleChangeValue}
                    defaultValue={this.props.field.data ? this.props.field.data : ''}
                />

                {this.state.patient.id && (
                    <div>
                        {t('agenda.appointment:coupling.text')}
                        <Link className="ml-1" to={reverse(routes.patients.edit, { id: this.props.value })} target="_blank">{this.state.patientName}</Link>. {' '}
                        {!this.props.field.readonly && <Link to="#" onClick={this.handleCouplingEnd} className="patient-coupling-end">{t('agenda.appointment:coupling.end')}</Link>}

                        {this.renderBlockedReason()}
                        {this.renderTags()}
                        {this.renderDeceased()}
                        {this.renderPendingPayments()}
                        {this.renderWarningMessage()}
                    </div>
                )}
            </React.Fragment>
        );
    }

    handleChangeValue = (e) => {
        e.preventDefault();

        const input = e.target;

        this.props.onChangeValue(input.name, input.value);

        this.assemblePatientName();
    }

    handleCouplingEnd = (e) => {
        e.preventDefault();
        this.props.onChangeValue(this.props.field.name, null);
    }

    assemblePatientName = () => {
        if (this.state.patientName) return;

        let patientFieldEl = document.getElementById(this.props.field.name);
        if (!patientFieldEl) return;

        let id = this.props.field.data;
        if (patientFieldEl.dataset.id) {
            id = patientFieldEl.dataset.id;
        }

        if (!id) return;

        this.setState({ loading: true }, () => ApiPatientService.getPatient(id, { start: this.props.data.start, end: this.props.data.end, client: this.props.data.clientId, category: this.props.data.categoryId, subcategory: this.props.data.subcategoryId })
            .then(result => {
                if (!result) return;
                this.setState({ loading: false, patient: result, patientName: result.firstName + ' ' + result.lastName }, () => {
                    this.handleAppointmentDuration();
                    UtilEvent.trigger('patientLoaded', { patient: result });
                });
            }));
    }

    renderBlockedReason = () => {
        let message = this.props.field.options.message ? this.props.field.options.message : this.state.patient.blockedReason;
        if (!message) return null;

        return <NotificationIcon color="danger" icon="ban" message={t('agenda.appointment:blockReasonLabel', { reason: message })} />
    }

    renderDeceased = () => {
        let deceased = this.props.field.options.deceased ? this.props.field.options.deceased : this.state.patient.deceased;
        if (!deceased) return null;

        return <NotificationIcon color="danger" icon="cross" message="Deze patiënt is overleden" />
    }

    renderWarningMessage = () => {
        if (!this.state.patient.warningMessage) return null;
        return <NotificationWarning message={this.state.patient.warningMessage} />
    }

    renderPendingPayments = () => {
        let pendingPayments = [];
        if (this.props.field.options.pendingPayments) {
            pendingPayments = this.props.field.options.pendingPayments;
        } else {
            pendingPayments = this.state.patient.pendingPayments;
        }

        if (!pendingPayments || !pendingPayments.length) return null;

        return <NotificationIcon color="danger" icon="euro-sign" message={t('settings.payment:tooltip.patient-pending')} />
    }

    renderTags = () => {
        let tags = [];
        if (this.props.field.options.tags) {
            tags = this.props.field.options.tags;
        } else if (this.state.patient.tags) {
            tags = this.state.patient.tags;
        }

        if (!tags.length) return null;

        return <div className="pt-1">
            {tags.map(tag => <BadgeTag tag={tag} key={tag.id} />)}
        </div>;
    }

    handleAppointmentDuration = () => {
        if (!this.props.form || !this.state.patient) return;
        if (!this.state.patient.appointmentDuration) return;

        let start = moment(this.props.form.state.data.start);
        let end = moment(this.props.form.state.data.end);
        let diff = end.diff(start, 'minutes');

        if (diff > this.state.patient.appointmentDuration) return;

        let newEnd = start.add(this.state.patient.appointmentDuration, 'minutes');
        this.props.form.handleManualChangeValue('end', newEnd.format('YYYY-MM-DD HH:mm:ss'));
    }
}

export default PatientField;