import React from 'react';
import { t } from 'i18next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import moment from 'moment';

class ModalMailingScheduleLog extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;

        this._columns = [{
            key: 'event',
            name: t('communication.mailing:common:event'),
            width: 150
        },
        {
            key: 'timestamp',
            name: t('communication.mailing:common:timestamp')
        }];

        this.state = this.initModal();
    }

    initModal = () => {
        return { modal: true };
    }

    createRows = () => {
        let rows = [];
        this.props.item.events.forEach(event => rows.push({
            event: t('communication.mailing:schedule:statistics:event:' + event.type),
            timestamp: moment(event.timestamp).format('DD/MM/YYYY HH:mm:ss')

        }));

        return rows;
    };

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillReceiveProps() {
        this._forceUpdate = true;
        this.setState(this.initModal());
    }

    componentDidUpdate() {
        if (this.props.item <= 0 && this._forceUpdate) {
            this._forceUpdate = false;
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        let originalRows = this.createRows();
        let rows = originalRows.slice(0);

        return (
            <Modal className="statuslogs" size="md" isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}>
                    <strong>Events</strong>
                </ModalHeader>
                <ModalBody>
                    <div style={{ maxWidth: '100%' }}>
                        <ReactDataGrid
                            columns={this._columns}
                            rowGetter={(i) => rows[i]}
                            rowsCount={rows.length}
                            minHeight={250}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    }

    fire = () => this.setState({ modal: true });
    onToggle = () => this.setState({ modal: !this.state.modal });
}

export default ModalMailingScheduleLog;