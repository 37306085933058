import React, { Component } from 'react';
import { t } from 'i18next';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { UtilEvent } from '../../../utils';

class TagButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            field: props.field
        };
    }

    render() {
        let { field } = this.state;

        if (['text', 'textarea', 'translatable_text', 'translatable_textarea'].indexOf(field.type) < 0) return null;
        if (!field.options.tags || field.options.tags <= 0) return null;

        return (
            <Dropdown id={'tags-' + field.name} className="d-inline-block" isOpen={this.state['tags-' + field.name]} toggle={() => this.setState({ ['tags-' + field.name]: !this.state['tags-' + field.name] })}>
                <DropdownToggle caret color="default" className="btn-labeled btn-xs pl-0">
                    <span className='btn-label'><em className='fa fa-tags'></em></span>
                    {t('common:placeholders')}
                </DropdownToggle>
                <DropdownMenu>
                    {Object.keys(field.options.tags).map(tag => (
                        <DropdownItem key={tag} onClick={() => UtilEvent.trigger('tagInsert' + field.name, { tag: tag })}>
                            {field.options.tags[tag]}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default TagButton;