import React from 'react';
import { FormGroup } from 'reactstrap';

class FormInline extends React.Component {
    render() {
        return <FormGroup row inline>
            {this.props.renderFields()}
        </FormGroup>
    }
}

export default FormInline;