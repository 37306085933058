import React from 'react';
import { t } from 'i18next';
import { Input, Table } from 'reactstrap';
import { TableBody, TableHead } from '../../table';
import { ButtonActionCustom } from '../../buttons';
import ModalMultipleCustomChoices from '../../modals/ModalMultipleCustomChoices';

class MultipleCustom extends React.Component {
    state = {
        data: this.props.field.data ? this.props.field.data : []
    };

    render() {
        return (
            <Table size="sm">
                <TableHead>
                    <tr>
                        <th>&nbsp;</th>
                        <th className='text-right'>
                            <ButtonActionCustom size="xs" color="success" icon="plus" tooltip={t('common:Add')} onClick={this.handOptionAddClick} />
                        </th>
                    </tr>
                </TableHead>
                <TableBody cols={2}>
                    {this.state.data.map((record, index) => (
                        <tr key={index}>
                            <td>
                                <Input type="text" name={'option-' + index} id={'option-' + index} value={record} onChange={e => this.handleOptionChange(index, e.target.value)} />
                            </td>
                            <td style={{ width: '8%' }} className='text-right'>
                                {this.props.field.options.choices && <ButtonActionCustom id={'add' + this.props.field.name + index} size="xs" color="default" icon="list" tooltip="Materialen" onClick={() => this._modalChoices.fire(index, this.props.field.options.choices)} />}
                                <ButtonActionCustom id={'remove' + this.props.field.name + index} size="xs" color="danger" icon="trash" tooltip={t('common:Remove')} onClick={() => this.handOptionRemoveClick(index)} />
                            </td>
                        </tr>
                    ))}
                </TableBody>

                {this.props.field.options.choices && <ModalMultipleCustomChoices onRef={ref => this._modalChoices = ref} choices={this.props.field.options.choices} onConfirm={this.handleOptionChange} />}
            </Table>
        );
    }

    handOptionAddClick = () => {
        let data = this.state.data;
        data.push('');
        this.setState({ data: data });
    }

    handOptionRemoveClick = (index) => {
        let data = this.state.data;
        data.splice(index, 1);
        this.setState({ data: data }, () => this.props.onChangeValue(this.props.field.name, data));
    }

    handleOptionChange = (index, value) => {
        let data = this.state.data;
        data[index] = value;
        this.setState({ data: data }, () => this.props.onChangeValue(this.props.field.name, data));
    }
}

export default MultipleCustom;