import { UtilArray } from "../../utils";
import Client from "../Client/Client";
import GroupSetting from "./GroupSetting";
import Subgroup from "./Subgroup";

export default class Group {
    id;
    name;
    logo_small;

    clients = [];
    subgroups = [];

    settings = [];

    static transform = (data, referer = []) => {
        let item = new Group();

        item.id = data.id;
        item.name = data.name;
        item.logo_small = data.logo_small;

        item.clients = data.clients.map(client => Client.transform(client, ['group']));

        if (!UtilArray.inArray(referer, 'subgroup')) {
            item.subgroups = data.subgroups.map(subgroup => Subgroup.transform(subgroup, ['group']));
        }

        item.settings = GroupSetting.transform(data.settings);

        return item;
    }

    getClient = (id) => this.clients.find(client => client.id === id);
    setting = (key, def = null) => this.settings[key] ? this.settings[key] : def;
}