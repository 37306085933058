import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const ButtonActionClone = props => (
    <React.Fragment>
        <Button color="info" size="sm" id={'buttonClone' + props.id} onClick={props.onClick}>
            <i className="fas fa-clone" aria-hidden></i>
        </Button>
        <UncontrolledTooltip target={'buttonClone' + props.id}>
            {t('common:action-clone')}
        </UncontrolledTooltip>
    </React.Fragment>
)

export default ButtonActionClone;
