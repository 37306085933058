import React from 'react';
import { Row, Col } from 'reactstrap';

class FormGrid extends React.Component {
    render() {
        return this.props.layout.rows.map((row, i) => (
            <Row key={'row' + i}>
                {row.map((col, i) => (
                    <Col key={'col' + i} xl={col.width} lg={12} id={col.id ? col.id : null}>
                        {this.props.renderFields(col.name)}

                        {col.additional && col.additional.map(section => <div className="mt-2" id={section} key={section}></div>)}
                        <div className="mt-2" id={col.id + '-additional-data'}></div>
                    </Col>
                ))}
            </Row>
        ));
    }
}

export default FormGrid;