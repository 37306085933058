import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Input } from 'reactstrap';
import ModalHeader from './partials/ModalHeader';

class ModalPatientRegistrationRejected extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: true,
            hasError: false
        }
    }

    componentWillReceiveProps() {
        this.setState({
            modal: true,
        });
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <ModalHeader title={t('patients.patient:modal.registration.reject:title')} onClose={this.onToggle} />

                <ModalBody className={'form-group ' + (this.state.hasError ? 'is-invalid' : '')}>
                    <Input type="textarea"
                        onChange={this.onChangeValue}
                        defaultValue=""
                        rows={5}
                    />
                    <small>{t('patients.patient:modal.registration.reject:help')}</small>
                </ModalBody>

                <ModalFooter className="text-center">
                    <Button color="info" className="btn-labeled" onClick={this.onConfirmClick}>
                        <span className="btn-label"><i className="fa fa-ban" aria-hidden></i></span>
                        <div className="btn-text">Bevestigen</div>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    onChangeValue = (e) => this.setState({ message: e.target.value.trim() });

    onConfirmClick = () => {
        this.onToggle();
        this.props.onConfirm(this.props.id, this.state.message);
    }

    onToggle = () => this.setState({ modal: !this.state.modal, hasError: false });
}

export default ModalPatientRegistrationRejected;