import React from 'react';

import { withRouter } from 'react-router-dom';
import GA4React from 'ga-4-react';

const RouteChangeTracker = ({ history }) => {
    history.listen((location, action) => {
        if (GA4React.isInitialized()) {
            let ga4 = GA4React.getGA4React();
            if (ga4) {
                ga4.pageview(location.pathname);
            }
        } else {
            let ga4react = new GA4React('G-41W2XBXVEW');
            ga4react.initialize().then(ga4 => {
                ga4.pageview(location.pathname);
            }, (err) => {
                // console.error(err)
            });
        }
    });

    return <div></div>
};

export default withRouter(RouteChangeTracker);