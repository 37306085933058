import { API } from '../api';

const getByClient = (params) => {
    return API.get('client/exception/exception/get-by-client', params)
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.exceptions;
            }

            return [];
        });
};

/*const updateSlotCategory = (slotId, categoryId, start, end, client, blocked, forceTiming = false) => {
    return API.post('client/exception/exception/update-category', {
        data: {
            category: categoryId,
            slotId: slotId,
            start: start,
            end: end,
            client: client,
            blocked: blocked,
            forceTiming
        }
    })
        .then(response => {
            return response;
        });
}*/

const updateSlotCategory = (data) => {
    return API.post('client/exception/exception/update-category', { data: data })
        .then(response => {
            if (API.isSuccess(response)) {
                return response;
            }

            throw new Error(response.data ? response.data[0] : '');
        });
}

const createSimple = (data, throwError = false) => {
    return API.post('client/exception/exception/create-exception-simple', { data: data })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.exception;
            } else if (throwError) {
                throw new Error(response.message ? response.message[0] : '');
            }
            return null;
        });
}

export const ApiExceptionService = {
    getByClient,
    updateSlotCategory,
    createSimple
};