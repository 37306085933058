import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Col, Row, Input } from 'reactstrap';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import moment from 'moment';
import { ApiNoteService } from '../../services/api/note.service';
import { UtilNotification } from '../../utils';
import TablePatientInfo from '../../containers/Communication/Task/Widgets/TablePatientInfo';
import { ApiPatientService } from '../../services/api/index';
import Spinner from '../reactstrap/Spinner';

class ModalNote extends React.Component {
    constructor(props) {
        super(props);

        let returnUrl = '';
        if (props.subgroup && props.subgroup.id) {
            returnUrl = reverse(routes.agenda.subgroup, { subgroup: props.subgroup.id, date: moment(props.note.start).format('YYYY-MM-DD-HH-mm') });
        } else if (props.groupOverview) {
            returnUrl = reverse(routes.agenda.group, { date: moment(props.note.start).format('YYYY-MM-DD-HH-mm') });
        } else {
            returnUrl = reverse(routes.agenda.client, { client: props.client, date: moment(props.note.start).format('YYYY-MM-DD-HH-mm') });
        }

        this.state = {
            modal: true,
            statusDropdown: false,
            note: props.note.note,
            visibleForCallCenter: props.note.visibleForCallCenter,
            returnUrl: returnUrl,
            hasError: false,
            hasPatient: props.note.patient && props.note.patient.id,
            patient: null,
            patientLoading: false
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            modal: true,
            hasPatient: newProps.note.patient && newProps.note.patient.id,
            patient: null
        }, () => {
            if (this.state.hasPatient) {
                this.loadPatient();
            }
        });
    }

    componentDidMount() {
        this.props.onModalRef(this);

        if (this.state.hasPatient) {
            this.loadPatient();
        }
    }

    componentWillUnmount() {
        this.props.onModalRef(null);
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <Row className="modal-header">
                    <Col md={12}>
                        <strong>{t('agenda.note:title')}</strong>
                    </Col>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>

                <ModalBody>
                    <div className={'form-group ' + (this.state.hasError ? 'is-invalid' : '')}>
                        <Input type="textarea"
                            onChange={this.onChangeValue}
                            defaultValue={this.props.note.note}
                            rows={5}
                        />
                        <div className="invalid-feedback">Gelieve een nota in te vullen</div>
                    </div>
                    <div className={'form-group'}>
                        <div className="checkbox c-checkbox">
                            <label>
                                <Input type="checkbox" value={1} defaultChecked={this.props.note.visibleForCallCenter} onChange={this.onChangeCallCenter} />
                                <span className="fa fa-check"></span> Zichtbaar voor telesecretariaat?
                            </label>
                        </div>
                    </div>

                    <Spinner visible={this.state.patientLoading} />
                    {this.state.patient && (
                        <TablePatientInfo {...this.props} settings={this.props.stateSettings} onClick={() => this.setState({ modal: false })} data={this.state.patient} showName />
                    )}
                </ModalBody>
                <ModalFooter className="text-center">
                    <div className="modal-buttons">
                        <Button color="info" className="btn-labeled" onClick={this.onEditClick}>
                            <span className="btn-label"><i className="fa fa-save" aria-hidden></i></span>
                            <div className="btn-text">{t('common:Save')}</div>
                        </Button>
                    </div>
                    <div className="modal-buttons">
                        <Button color="danger" className="btn-labeled" onClick={() => this.onRemoveClick(false)}>
                            <span className="btn-label"><i className="fa fa-trash" aria-hidden></i></span>
                            <div className="btn-text">{t('common:Remove')}</div>
                        </Button>

                        <Button color="danger" className="btn-labeled" onClick={() => this.onRemoveClick(true)}>
                            <span className="btn-label"><i className="fa fa-trash" aria-hidden></i></span>
                            <div className="btn-text">{t('agenda.note:removeAllAgendas')}</div>
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    onChangeValue = (e) => {
        let value = e.target.value.trim();
        this.setState({ note: value, hasError: !(!!value) });
    }

    onChangeCallCenter = (e) => this.setState({ visibleForCallCenter: e.target.checked });

    onEditClick = () => {
        const { note } = this.state;

        if (!note) {
            this.setState({ hasError: true });
            return;
        }

        ApiNoteService.quickUpdate(this.props.note.id, { update: 'value', note: this.state.note, visibleForCallCenter: this.state.visibleForCallCenter })
            .then(result => {
                if (result) {
                    this.setState({ modal: false, hasError: false }, () => {
                        UtilNotification.success(t('agenda.note:edit.successfull'))
                        this.props.history.push(this.state.returnUrl)
                    });
                }
            })
            .catch(error => UtilNotification.toastError());
    }

    onRemoveClick = (allAgandas) => {
        this.props.removeModal.fire({ id: this.props.note.id, all: allAgandas }, { redirectUrl: this.state.returnUrl });
        this.onToggle();
    }

    onToggle = () => this.setState({ modal: !this.state.modal, hasError: false });
    loadPatient = () => this.setState({ patientLoading: true }, () => ApiPatientService.getPatient(this.props.note.patient.id).then(result => this.setState({ patient: result, patientLoading: false })));
}

export default ModalNote;