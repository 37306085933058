import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalFooter } from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import { BadgeCategory, BadgeStatus, BadgeSubcategory } from '../badge';
import { ApiAppointmentService } from '../../services/api/appointment.service';
import moment from 'moment';
import ModalHeader from './partials/ModalHeader';
import ModalBody from './partials/ModalBody';
import { UtilNotification } from '../../utils';

class ModalAppointmentLog extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;

        this._columns = [{
            key: 'date',
            name: t('modal.appointmentstatuslog:modal.table.date'),
            width: 175,
            resizable: true
        },
        {
            key: 'type',
            name: t('modal.appointmentstatuslog:modal.table.type'),
            width: 200,
            resizable: true
        },
        {
            key: 'assigned',
            name: t('modal.appointmentstatuslog:modal.table.assigned'),
            width: 200,
            resizable: true
        },
        {
            key: 'message',
            name: t('modal.appointmentstatuslog:modal.table.message'),
            resizable: true
        }];

        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            modal: true,
            logs: []
        };
    }

    rowGetter = (i) => this.state.rows[i]

    handleGridSort = (sortColumn, sortDirection) => {
        const comparer = (a, b) => {
            if (sortDirection === 'ASC') {
                return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
            }
        };

        const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);

        this.setState({ rows });
    };

    createRows = () => this.state.logs.map(log => {
        let message = [];

        let ignoreMessageTypes = ['updated-categoryId', 'updated-subCategoryId', 'updated-status'];
        if (!ignoreMessageTypes.includes(log.type)) {
            message.push(this.renderMessage(log));
        }

        if (log.extraData.category) {
            message.push(this.renderCategoryBadge(log));
        }
        if (log.extraData.subcategory) {
            message.push(this.renderSubcategoryBadge(log));
        }
        if (log.extraData.status) {
            message.push(this.renderStatusBadge(log));
        }
        if (log.extraData.notification) {
            message.push(log.extraData.notification.title);
        }

        return {
            date: moment(log.dateCreated, 'DD/MM/YYYY HH:mm:ss').format('ddd DD/MM/YYYY HH:mm:ss'),
            type: t('modal.appointmentstatuslog:modal.type.' + log.type),
            assigned: log.assignedBy,
            message: message
        }
    });

    renderMessage = (log) => <span key={Math.random().toString(36).substring(2, 9)} className="mr-2">{log.message ? log.message : '-'}</span>

    renderCategoryBadge = (log) => {
        const { category } = log.extraData;

        let title = category.title;
        if (category.id in this.props.schemaData.categories) {
            title = this.props.schemaData.categories[category.id].name
        }

        return <BadgeCategory className="mr-2" id={category.id} title={title} />
    }

    renderSubcategoryBadge = (log) => {
        const { subcategory } = log.extraData;
        const { subcategories } = this.props.schemaData;

        let title = subcategory.title;
        if (subcategory.id in subcategories) {
            title = subcategories[subcategory.id].name
        }

        return <BadgeSubcategory className="mr-2" id={subcategory.id} title={title} />
    }

    renderStatusBadge = (log) => {
        const { status } = log.extraData;
        const { statuses } = this.props.schemaData;

        let title = status.title;
        if (status.id in statuses) {
            title = statuses[status.id].title
        }
        return <BadgeStatus className="mr-2" id={status.id} title={title} />
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.load();
    }

    componentWillReceiveProps() {
        this._forceUpdate = true;
        this.setState(this.initModal());
    }

    componentDidUpdate() {
        if (this.state.logs.length <= 0 && this._forceUpdate) {
            this._forceUpdate = false;
            this.load();
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        let rows = this.createRows().slice(0);

        return (
            <Modal isOpen={this.state.modal} size="xl" toggle={this.onToggle} className="statuslogs modal-appointment container-fluid">
                <ModalHeader onClose={this.onToggle} title={t('modal.appointmentstatuslog:modal.title')} />

                <ModalBody loading={this.state.loading}>
                    <div style={{ maxWidth: '100%' }}>
                        <ReactDataGrid
                            onGridSort={this.handleGridSort}
                            columns={this._columns}
                            // rowGetter={this.rowGetter}
                            rowGetter={(i) => rows[i]}
                            rowsCount={rows.length}
                            minHeight={250}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.onToggle}>{t('common:Close')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    fire = () => this.setState({ modal: true });
    onToggle = () => this.setState({ modal: !this.state.modal });

    load = () => ApiAppointmentService.getLogsByAppointment(this.props.appointmentId).then(result => {
        if (result) {
            this.setState({ loading: false, logs: result.logs });
        } else {
            UtilNotification.toastError();
        }
    });
}

export default ModalAppointmentLog;