import React from 'react';
import { t } from 'i18next';
import { ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import routes from '../../../routing/routes';
import { UtilClient, UtilUrl } from '../../../utils';
import { reverse } from 'named-urls';

class SidebarClient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ddOpen: false
        };
    }

    changeClient = (item) => {
        let { location } = this.props;

        this.props.actions.clientSet('active', item);

        //reload
        if (!location.pathname.startsWith('/agenda') && !location.pathname.startsWith('/agenda/group')) {
            if (!item.hasAgenda && location.pathname.startsWith('/settings/profiles')) {
                this.props.history.push(reverse(routes.settings.categories.overview));
            } else {
                this.props.history.push(this.props.location.pathname);
            }
        } else {
            let params = { client: item.id };
            let date = location.pathname.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g);
            if (date) { params['date'] = date[0]; }

            if (!item.hasAgenda) {
                this.props.history.push(UtilUrl.generate(routes.agenda.group, params));
            } else {
                this.props.history.push(UtilUrl.generate(routes.agenda.client, params));
            }
        }
    }

    toggle = () => this.setState({ ddOpen: !this.state.ddOpen })

    render() {
        if (this.props.group.clients === undefined) return null;

        let activeClient = this.props.client;
        let btnColor = this.props.group ? 'brand' : 'danger';
        let wideMenu = this.props.group.clients.filter(client => client.fullName.length > 20).length;

        return (
            <ButtonDropdown color={btnColor} isOpen={this.state.ddOpen} toggle={this.toggle} className="btn-block">
                <DropdownToggle color={btnColor} size="sm" caret>
                    {activeClient ? activeClient.fullName : t('common:choose.client')}
                </DropdownToggle>
                <DropdownMenu className={wideMenu ? 'dropdown-menu-wide' : ''}>
                    {this.props.group.clients.filter(client => UtilClient.hasAccessToAgenda(this.props.loggedInClient, client.id)).map(item => (
                        <DropdownItem key={item.id} className={wideMenu ? 'dropdown-item-ws-normal' : ''} onClick={() => this.changeClient(item)} active={item.id !== activeClient.id ? false : true}>
                            {item.fullName}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ButtonDropdown>
        );
    }
}

const mapStateToProps = state => ({ client: state.client.active, loggedInClient: state.client.logged_in, group: state.group })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(SidebarClient);