import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';
import { ApiCommonService } from '../../services/api/common.service';
import { UtilNotification } from '../../utils/notification.util.js';

class ModalRemoveAppointment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            inProgress: false,
            repeated: false,
            request: {
                url: props.removeUrl,
                urlRepeated: props.removeRepeatedUrl,
                params: {},
                redirectUrl: props.redirectUrl,
                successMessage: props.successMessage,
                actionAfterRemove: props.actionAfterRemove
            },
            options: {
                title: props.title ? props.title : null,
                text: props.text ? props.text : null,
                buttons: [
                    { text: t('common:Cancel'), color: 'default', type: 'cancel' },
                    { text: t('common:Confirm'), color: 'info', type: 'confirm' },
                ],
                buttonsRepeated: [
                    { text: t('common:Cancel'), color: 'default', type: 'cancel' },
                    { text: t('agenda.myagenda:contextMenu:remove-repeated'), color: 'info', type: 'confirm' },
                    { text: t('agenda.myagenda:contextMenu:remove-repeated-upcoming'), color: 'info', type: 'confirm-upcoming', repeatedOnly: true },
                    { text: t('agenda.myagenda:contextMenu:remove-repeated-all'), color: 'info', type: 'confirm-all', repeatedOnly: true }
                ],
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { options } = this.state;

        let modalHeader, modalBody;

        if (options.title) {
            modalHeader = (
                <ModalHeader>{options.title}</ModalHeader>
            );
        }

        if (options.text) {
            modalBody = (
                <ModalBody>
                    {this.state.inProgress ?
                        <><span className='fa fa-spinner fa-spin mr-2'></span>{t('common:remove.in_progress')}</> :
                        options.text
                    }
                </ModalBody>
            );
        }

        let buttons = this.state.repeated ? options.buttonsRepeated : options.buttons;

        return (
            <Modal isOpen={this.state.open}>
                {modalHeader}
                {modalBody}

                <ModalFooter>
                    {buttons.map((btn, i) => {
                        return !this.state.repeated && btn.repeatedOnly ?
                            null :
                            <Button key={i} color={btn.color} disabled={this.state.inProgress} onClick={() => this.handle(btn.type)}>{btn.text}</Button>
                    })}
                </ModalFooter>
            </Modal>
        );
    }

    fire = (params, request = {}) => {
        let stateRequest = this.state.request;
        stateRequest = Object.assign(stateRequest, request);
        stateRequest = {
            ...stateRequest,
            params: params
        }

        this.setState({
            request: stateRequest,
            open: true,
            repeated: 'repeatId' in params && params.repeatId ? true : false
        });
    };

    handle = (e) => {
        switch (e) {
            case 'confirm':
            case 'confirm-upcoming':
            case 'confirm-all':
                this.removeItem(e);
                break;
            default:
                this.setState({ open: false });
                break;
        }
    }

    removeItem = (type) => this.setState({ inProgress: true }, () => {
        let { url, urlRepeated, params, redirectUrl, successMessage, actionAfterRemove } = this.state.request;

        if (type !== 'confirm') {
            url = urlRepeated;
            params.id = params.repeatId;
        }
        delete params.repeatId;

        if (params.dateFrom && ['confirm', 'confirm-all'].includes(type)) {
            delete params.dateFrom;
        }

        ApiCommonService.removeItem(url, params)
            .then(() => {
                if (actionAfterRemove) {
                    actionAfterRemove(params.id);
                } else if (redirectUrl) {
                    UtilNotification.success(successMessage);
                }
            })
            .catch(error => {
                if (actionAfterRemove) {
                    UtilNotification.toastError(error);
                } else if (redirectUrl) {
                    UtilNotification.error(error);
                }
            })
            .then(() => {
                if (redirectUrl) {
                    this.props.history ? this.props.history.push(redirectUrl) : window.location = redirectUrl;
                } else if (this.props.onFinish) {
                    UtilNotification.toastSuccess(successMessage);
                    this.props.onFinish(params.id);
                }

                this.setState({ inProgress: false, open: false });
            });
    });
}

export default ModalRemoveAppointment;