import { API } from '../api';

const quickUpdate = (id, data) => {
    return API.post('client/note/note/quick-update', { id: id, data: data })
        .then(response => {
            if (response.status === 'success') {
                return response.data;
            }

            throw response.data[Object.keys(response.data)[0]];
        });
};

export const ApiNoteService = {
    quickUpdate
};