import React from 'react';
import { Input } from 'reactstrap';

class HidddenField extends React.Component {
    render() {
        const { name, type, data } = this.props.field;

        return (
            <Input type={type}
                name={name}
                id={name}
                onChange={this.props.onChangeValue}
                defaultValue={data ? data : ''} />
        );
    }
}

export default HidddenField;