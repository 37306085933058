import { Task } from '../../models';
import { API } from '../api';

const get = (id) => API.get('client/task/task/get', { id: id }).then(response => API.isSuccess(response) ? Task.transform(response.data.task) : null);
const getByClient = () => API.get('client/task/task/get-by-client').then(response => API.isSuccess(response) ? response.data.tasks : []);
const getByGroup = (groupId) => API.get('client/task/task/get-by-group', { groupId: groupId }).then(response => API.isSuccess(response) ? response.data.tasks : []);
const getByPatient = (patientId) => API.get('client/task/task/get-by-patient', { patientId: patientId }).then((response) => API.isSuccess(response) ? response.data.tasks : []);
const logs = (id) => API.get('client/task/task/logs', { id: id }).then(response => API.isSuccess(response) ? response.data.logs : []);
const quickUpdate = (id, data) => API.post('client/task/task/quick-update', { id: id, data: data }).then(response => API.isSuccess(response));
const search = (data) => API.get('client/task/task/search', data).then(response => API.isSuccess(response) ? response.data.tasks.map(task => Task.transform(task)) : []);
const confirmDecline = (id, confirm, message) => API.post('client/task/task/confirm', { data: { id: id, confirm: confirm, message: message } }).then(response => API.isSuccess(response));
const getGroupsByGroup = (groupId) => API.get('client/task/group/get-by-group', { groupId: groupId }).then(response => API.isSuccess(response) ? response.data.groups : []);

export const ApiTaskService = {
    get,
    getByClient,
    getByGroup,
    getByPatient,
    quickUpdate,
    logs,
    search,
    confirmDecline,

    getGroupsByGroup
};