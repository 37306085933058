import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { Row, Col, Card, CardHeader, CardBody, CardFooter, Label, FormFeedback, FormGroup } from 'reactstrap';
import FormValidator from './../../utils/form.validator';
import TextField from './Elements/TextField';
import TelField from './Elements/TelField';
import ChoiceField from './Elements/ChoiceField';
import ColorField from './Elements/ColorField';
import CheckboxField from './Elements/CheckboxField';
import TextareaField from './Elements/TextareaField';
import EditorField from './Elements/EditorField';
import DateField from './Elements/DateField';
import DatetimeField from './Elements/DatetimeField';
import HiddenField from './Elements/HiddenField';
import RepeatedField from './Elements/RepeatedField';
import PatientField from './Elements/PatientField';
import NumberField from './Elements/NumberField';
import { ApiFormService } from '../../services/api/form.service';
import { UtilAppointment } from '../../utils/appointment.util';
import CommunicationField from './Elements/CommunicationField';
import ImageField from './Elements/ImageField';
import TranslatableTextField from './Elements/TranslatableTextField';
import TranslatableTextareaField from './Elements/TranslatableTextareaField';
import TranslatablEditorField from './Elements/TranslatablEditorField';
import { connect } from 'react-redux';
import { ButtonSave, Loader } from '..';
import FormTab from './FormTab';
import FormGrid from './FormGrid';
import FormInline from './FormInline';
import CollectionModalField from './Elements/CollectionModalField';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { UtilDate } from '../../utils/date.util';
import { UtilUrl } from '../../utils/url.util';
import { Link } from 'react-router-dom';
import routes from '../../routing/routes';
import { UtilEvent } from '../../utils/event.util';
import { reverse } from 'named-urls';
import { ButtonActionCustom, ButtonCancel } from '../buttons';
import { UtilArray, UtilNotification } from '../../utils';
import RouteLeavingGuard from '../app/RouteLeavingGuard';
import TagButton from './Widgets/TagButton';
import ChoicePostalField from './Elements/ChoicePostalField';
import MultipleCustom from './Elements/MultipleCustom';
import MaterialField from './Elements/MaterialField';
import TagField from './Elements/TagField';
import CollectionField from './Elements/CollectionField';

class Form extends React.Component {
    definedHiddenFields = ['groupId', 'clientId', 'scheduleId'];

    constructor(props, extraProps) {
        super(props);

        this._isMounted = false;
        this._forceUpdate = false;

        this.routeLeavingGuard = null;
        this.appointmentEndDateRef = null;
        this._nationalInsuranceNumberBinded = false;

        this.extraProps = { ...props, ...extraProps };

        this.state = this.initState(extraProps);
    }

    initState = (extraProps) => {
        return {
            loading: true,
            submitUrl: '',
            submitDisabled: false,
            sections: {},
            hiddenPostFields: [],
            patientLinkFields: {},
            patientDataFields: {},
            actionFields: {},
            data: {},
            setOnChange: {},
            errors: {},
            extraPostData: extraProps.postData ? extraProps.postData : {},
            extraRequestData: this.props.requestData ? this.props.requestData : (extraProps.requestData ? extraProps.requestData : {}),
            extraLinks: [],
            result: {},
            submitOnEnter: true
        }
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.load();

        let body = document.querySelector('body');
        if (body && !!body.getAttribute('keydown-listener') !== true) {
            body.addEventListener('keyup', this.handleKeyDown);
            body.setAttribute('keydown-listener', 'true');
        }
    }

    componentWillReceiveProps(newProps) {
        let oldKey = this.props.url + '-' + this.props.type;
        let newKey = newProps.url + '-' + newProps.type;

        if (oldKey !== newKey) {
            this._forceUpdate = true;
            this.extraProps = { ...this.props, ...newProps };
            this.setState(this.initState(newProps));
        }
    }

    componentDidUpdate() {
        if (this.props.view && this.props.view.onFormRendered) {
            this.props.view.onFormRendered(this.state.result);
        } else if (this.props.onFormRendered) {
            this.props.onFormRendered(this.state.result);
        }

        this.appendSubmitToHeader();
        this.bindNationalInsuranceNumberListeners();

        if (this._forceUpdate) {
            this._forceUpdate = false;
            this.load();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.onRef) {
            this.props.onRef(null);
        }

        let body = document.querySelector('body');
        if (body) {
            body.removeEventListener('keyup', this.handleKeyDown);
        }

        this.removeSubmitHeader();
    }

    removeSubmitHeader = () => {
        const headingEl = document.getElementById('heading-end');
        if (!headingEl) return;

        for (let i = 0; i < headingEl.childNodes.length; i++) {
            if (headingEl.childNodes[i].classList.contains('form-api-submit')) {
                headingEl.removeChild(headingEl.childNodes[i]);
            }
        }
    }

    appendSubmitToHeader = () => {
        if (this.props.preventHeaderManipulation) return;

        const headingEl = document.getElementById('heading-end');
        if (!headingEl || this.state.loading || this.props.noTopSaveButton) return;

        this.removeSubmitHeader();

        let submitEl = document.createElement('div');
        submitEl.classList.add('form-api-submit');

        headingEl.appendChild(submitEl);

        ReactDOM.render(<ButtonSave icon={this.props.submitBtnIcon} disabled={this.state.submitDisabled} onClick={() => this.onSubmit('save')}>{t(this.props.submitBtnTitle)}</ButtonSave>, submitEl);
    }

    handleChangeValue = e => {
        //automatische invulling bij een onChange in child (default)
        var input = e.target;
        var data = this.state.data;

        data[input.name] = input.type === 'checkbox' ? input.checked : input.value;

        let fieldName = this.getHiddenFieldToSet(input.name);
        if (fieldName) {
            data[fieldName] = data[input.name];
        }

        this.setState({ data: data });

        UtilEvent.trigger('valueChanged', {
            changedField: input.name,
            newValue: data[input.name]
        });

        this.askPageLeaveConfirmation(true);

        if (this.props.postChangeValue) {
            this.props.postChangeValue(input.name, data);
        }
    };

    handleManualChangeValue = (name, value) => {
        // manuele invulling bij een onChange in child (bvb bij een "moment")
        var data = this.state.data;

        data[name] = value;
        let fieldName = this.getHiddenFieldToSet(name);
        if (fieldName) {
            data[fieldName] = value;
        }

        let action = this.state.actionFields[name];
        if (action && this.props.onAction) {
            this.props.onAction(action, data);
            return null;
        }

        if ((name === 'patientId' && value === null) || action === 'reload') {
            let params = { client: data.clientId, start: UtilDate.format(data.start, 'YYYYMMDDHHmmss'), end: UtilDate.format(data.end, 'YYYYMMDDHHmmss'), category: data.categoryId, slot: data.slot, subcategory: 0, force: 1 };
            if (data.subcategoryId) {
                params['subcategory'] = data.subcategoryId;
            }

            let link = null;
            if (this.props.match.path.includes('/tasks/')) {
                link = UtilUrl.generate(routes.communication.tasks.add, params);
            } else {
                link = UtilUrl.generate(routes.appointments.add, params);
            }

            this.props.history ? this.props.history.push(link) : window.location = link;
            return null;
        }

        if (name === 'start' || name === 'startDate') {
            const endField = name === 'start' ? 'end' : 'endDate';

            let newEnd = moment(value).format('YYYY-MM-DD') + ' ' + moment(data[endField]).format('HH:mm:ss');

            if (newEnd <= value) {
                let s = moment(value);
                newEnd = s.add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss');
            }

            let updateEnd = true;
            if (name === 'startDate' && data[endField] > value) {
                updateEnd = false;
            }

            let field = this.getFormField(name);
            if (field && field.options && field.options.prevent_enddate_update) {
                updateEnd = false;
            }

            if (updateEnd) {
                data[endField] = newEnd;

                if (this.appointmentEndDateRef && this.appointmentEndDateRef.changeEndDate) {
                    this.appointmentEndDateRef.changeEndDate(UtilDate.create(newEnd));
                }
            }
        }

        this.setState({ data: data, sections: this.state.sections });

        UtilEvent.trigger('valueChanged', { changedField: name, newValue: value });

        this.askPageLeaveConfirmation(true);

        if (this.props.postChangeValue) {
            this.props.postChangeValue(name, data);
        }
    };

    getErrors = (fieldName) => {
        if (typeof this.state.errors[fieldName] !== 'undefined') {
            //FormValidator.js mergt alle errors in een custom prop
            return this.state.errors[fieldName]['custom'];
        }
        return false;
    }

    validateOnChange = e => {
        const input = e.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            errors: {
                ...this.state.errors,
                [input.name]: result,
                [input.value]: value
            }
        });
    };

    onSubmit = (target = 'save') => {
        this.setState({ submitDisabled: true, errors: {} });

        if (this.props.onPreSubmit) {
            this.props.onPreSubmit();
        }

        let postData = {};
        for (let key in this.state.data) {
            postData[key] = this.state.data[key];
        }

        for (let i in this.state.hiddenPostFields) {
            let key = this.state.hiddenPostFields[i];

            switch (key) {
                case 'groupId':
                    postData[key] = this.state.data.groupId ? this.state.data.groupId : this.props.group.id;
                    break;
                case 'clientId':
                    postData[key] = this.state.data.clientId ? this.state.data.clientId : this.props.client.id;
                    break;
                case 'scheduleId':
                    postData[key] = this.extraProps.id;
                    break;
                default:
                    alert('voeg ' + key + ' toe in Form.js');
            }
        }

        if (target === 'fake') {
            if (this.props.onFakeSubmit) {
                this.props.onFakeSubmit(postData);
            }
        } else {
            Object.assign(postData, this.state.extraPostData, { save: target });
            // console.log(postData); return false;
            // const { errors, hasError } = FormValidator.bulkValidate(inputs)    

            this.send(postData);
        }
    }

    send = (data) => ApiFormService.submit(this.state.submitUrl, this.extraProps.id, data)
        .then(response => {
            if (this.routeLeavingGuard) {
                this.routeLeavingGuard.setConfirmedNavigation(true);
            }

            if (this.props.view && this.props.view.onSubmit) {
                this.props.view.onSubmit(response, data);
            } else if (this.extraProps.onSubmit) {
                this.extraProps.onSubmit(response, data);
            } else {
                if (this.extraProps.performOnSubmit) {
                    this.extraProps.performOnSubmit(response.data);
                }

                UtilNotification.success(this.props.successMessage);

                this.props.history ?
                    this.props.history.push(this.extraProps.returnUrl) :
                    window.location = this.extraProps.returnUrl;
            }
        })
        .catch(error => {
            if (!this.props.ignoreErrorToast) {
                UtilNotification.toastError(t('common:form.submit.error'));
            }

            this.setState({ errors: FormValidator.validateApiFormResponse(error) }, () => {
                if (this.props.onFormError) {
                    this.props.onFormError(error);
                }
            });
        })
        .then(() => {
            if (!this._isMounted) return;
            this.setState({ submitDisabled: false })
        });

    render() {
        if (this.state.loading) return <Loader show />;

        return (
            <form id="form-api" className="form" onSubmit={(e) => { e.preventDefault(); }} autoComplete={'off'}>
                {this.props.checkPageLeave !== false && <RouteLeavingGuard {...this.props} onRef={ref => (this.routeLeavingGuard = ref)} />}
                <Card className="card-default">
                    {this.props.title && (
                        <CardHeader>
                            <strong>{this.props.title}</strong>
                        </CardHeader>
                    )}
                    <CardBody>
                        {this.renderLayout()}
                    </CardBody>
                    {!this.props.hideFooter && (
                        <CardFooter>
                            <div className="d-flex align-items-center">
                                <div style={{ float: 'left' }}>
                                    {this.state.extraLinks && this.state.extraLinks.map((link, i) => (
                                        <div key={i}>
                                            <Link to={{ pathname: UtilUrl.generate(routes.auth[link.type]) }}>
                                                {link.text}
                                            </Link>
                                        </div>
                                    ))}
                                </div>

                                <div className="ml-auto">
                                    {this.props.returnUrl && <ButtonCancel to={{ pathname: this.props.returnUrl }} forcePath={this.props.forceReturn} />}

                                    <ButtonSave type={this.state.submitOnEnter ? 'submit' : 'button'} icon={this.props.submitBtnIcon} disabled={this.state.submitDisabled} onClick={() => this.onSubmit('save')} className="d-none">
                                        Submit
                                    </ButtonSave>

                                    <ButtonSave type="button" icon={this.props.submitBtnIcon} disabled={this.state.submitDisabled} onClick={() => this.onSubmit('save')}>
                                        {t(this.props.submitBtnTitle)}
                                    </ButtonSave>

                                    {this.props.view && this.props.view.renderFormButtons ? this.props.view.renderFormButtons(this.state.submitDisabled) : null}
                                </div>
                            </div>
                        </CardFooter>
                    )}
                </Card>
            </form>
        );
    }

    renderLayout = () => {
        let type = this.props.layout ? this.props.layout.type : null;

        switch (type) {
            case 'tab':
                return <FormTab renderFields={this.renderSection} layout={this.props.layout} />
            case 'grid':
                return <FormGrid renderFields={this.renderSection} layout={this.props.layout} />
            case 'inline':
                return <FormInline renderFields={this.renderSection} layout={this.props.layout} />
            default:
                return this.renderSection('undefined');
        }
    }

    renderSection = (key) => {
        let section = this.state.sections[key] ? this.state.sections[key] : {};

        // 2 default groups (0 and last)
        let showGroupHeader = Object.keys(section).length >= (this.props.layout && 'showGroupHeaderLength' in this.props.layout ? this.props.layout.showGroupHeaderLength : 3);

        let fields = [];
        Object.keys(section).forEach(g => {
            if (showGroupHeader && g > 0) {
                fields.push(this.renderHeader({ key: g, title: t('agenda.appointment:form_group_' + g + ':title') }));
            }

            section[g].forEach((group, i) => {
                let field = this.renderGroup(group, i);
                if (field) fields.push(field);
            });
        });

        return fields
    }

    renderHeader = (group) => (
        <div className="heading mx-0 mt-1" key={group.key}>
            <div className="heading-start">
                <h3 className="heading-title fw-600">{group.title}</h3>
                {group.subtitle && <h4 className="heading-subtitle">{group.subtitle}</h4>}
            </div>

            <div className="heading-end">
                <ButtonActionCustom id="save" size="sm" icon="save" tooltip={t(this.props.submitBtnTitle)} disabled={this.state.submitDisabled} onClick={() => this.onSubmit('save')} />
            </div>
        </div>
    )

    renderGroup = (group, i) => {
        if (group.length === 1) {
            return this.renderField(group[0]);
        }

        let visibleFields = group.filter(field => this.showField(field));

        return (
            <Row key={i}>
                {visibleFields.map(field => {
                    if (!this.showField(field)) return null;

                    let width = field.options.width;
                    if (!width) width = 12 / visibleFields.length;

                    return (
                        <Col key={'col' + field.name} xl={width}>
                            {this.renderField(field)}
                        </Col>
                    )
                })}
            </Row>
        );
    }

    renderField = (field) => {
        if (!this.showField(field)) return false;

        const { name, label, required, help, type } = field;
        const errors = this.getErrors(name);

        return (
            <FormGroup key={'fgroup' + field.name} id={'form-group-' + name} className={'form-group' + (errors ? ' is-invalid' : '') + ' ' + (type === 'hidden' ? 'form-group-hidden' : '')}>
                {this.showLabel(field) && <>
                    <Label className="col-form-label" for={name}>{label ? label : '\u00A0'} {label && required ? '*' : ''}</Label>
                    <TagButton field={field} />
                </>}
                {this.getFieldElement(field)}
                {this.renderExtra(field)}
                {errors && <FormFeedback>{errors}</FormFeedback>}
                {help !== 'undefined' && <div className="text-sm text-muted text-help">{help}</div>}
                <div id={'autosuggest-' + name} className="form-autosuggest"></div>
            </FormGroup>
        );
    }

    renderExtra = (field) => {
        if (!field.options.key) return;
        if (field.options.key === 'mailing_list_filters') {
            return <Link to={{ pathname: reverse(routes.patients.overview, { search: 1 }) }}>{t('communication.mailing:list:filters.add')}</Link>
        }
    }

    getFieldElement = (field) => {
        const sectionLength = Object.entries(this.state.sections).length;

        if (field.name === 'end' || field.name === 'endDate') {
            switch (field.type) {
                case 'datetime':
                    return <DatetimeField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} small={sectionLength > 1 ? true : false} onRef={ref => (this.appointmentEndDateRef = ref)} validations={['required']} />
                case 'date':
                    return <DateField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} small={sectionLength > 1 ? true : false} onRef={ref => (this.appointmentEndDateRef = ref)} validations={['required']} />
                default:
                    return <TextField key={field.name} onChangeValue={this.handleChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            }
        }

        switch (field.type) {
            case 'datetime':
                return <DatetimeField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} small={sectionLength > 1 ? true : false} validations={['required']} />
            case 'date':
                return <DateField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'checkbox':
                return <CheckboxField key={field.name} onChangeValue={this.handleChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'textarea':
                return <TextareaField key={field.name} onManualChangeValue={this.handleManualChangeValue} onChangeValue={this.handleChangeValue} data={this.state.data} validateOnChange={this.validateOnChange} field={field} validations={['required']} />
            case 'translatable_textarea':
                return <TranslatableTextareaField key={field.name} onManualChangeValue={this.handleManualChangeValue} onChangeValue={this.handleChangeValue} data={this.state.data} validateOnChange={this.validateOnChange} field={field} validations={['required']} />
            case 'editor':
                return <EditorField key={field.name} onManualChangeValue={this.handleManualChangeValue} onChangeValue={this.handleChangeValue} data={this.state.data} group={this.props.group} validateOnChange={this.validateOnChange} field={field} validations={['required']} />
            case 'translatable_editor':
                return <TranslatablEditorField key={field.name} onManualChangeValue={this.handleManualChangeValue} onChangeValue={this.handleChangeValue} data={this.state.data} validateOnChange={this.validateOnChange} field={field} validations={['required']} />
            case 'choice':
                //field.data = ['1','3'];
                //field.data = '1';
                //field.data = 'en';
                return <ChoiceField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'choice_postal':
                return <ChoicePostalField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} parent={this} />
            case 'color':
                return <ColorField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'hidden':
                if (this.definedHiddenFields.indexOf(field.name) > -1) {
                    return '';
                } else {
                    return <HiddenField key={field.name} onChangeValue={this.handleChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
                }
            case 'repeated':
                return <RepeatedField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'patient':
                return <PatientField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} value={this.state.data[field.name]} form={this} validations={['required']} />
            case 'communication_mail':
            case 'communication_sms':
                return <CommunicationField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} value={this.state.data[field.name]} data={this.state.patientDataFields.to} validations={['required']} />
            case 'image':
                return <ImageField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} actions={this.props.actions} validations={['required']} />
            case 'collection':
                if (field.name === 'options') { // Form field options - fixed
                    return <CollectionModalField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
                }
                return <CollectionField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'tel':
                return <TelField key={field.name} onChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'number':
                return <NumberField key={field.name} onChangeValue={this.handleChangeValue} onManualChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'translatable_text':
            case 'translatable_url':
                return <TranslatableTextField key={field.name} onChangeValue={this.handleChangeValue} onManualChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'material':
                return <MaterialField key={field.name} onChangeValue={this.handleManualChangeValue} onManualChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'multiple-custom':
                return <MultipleCustom key={field.name} onChangeValue={this.handleManualChangeValue} onManualChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} validations={['required']} />
            case 'tags':
                return <TagField key={field.name} onChangeValue={this.handleChangeValue} onManualChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} form={this} validations={['required']} />
            default:
                return <TextField key={field.name} onChangeValue={this.handleChangeValue} onManualChangeValue={this.handleManualChangeValue} validateOnChange={this.validateOnChange} field={field} data={this.state.data} form={this} validations={['required']} />
        }
    }

    showLabel = (field) => {
        switch (field.type) {
            case 'checkbox':
            case 'hidden':
            case 'patient':
            case 'radio':
            case 'repeated':
                return false;
            default:
                return true;
        }
    }

    load = (extraRequestData = {}, preserveData = false) => {
        //this.setState({ loading: true });

        const requestData = Object.assign(
            { type: this.extraProps.type, id: this.extraProps.id, groupId: (this.props.group ? this.props.group.id : null) },
            this.state.extraRequestData,
            extraRequestData
        );

        ApiFormService.load(this.extraProps.url, requestData)
            .then(result => {
                let originalData = this.state.data;

                let data = {};
                let setOnChange = {};
                let patientLinkFields = {};
                let patientDataFields = {};
                let hiddenPostFields = [];
                let actionFields = {};
                let formGroups = { 0: [], 1: [], 2: [] };
                let groupId = this.props.group ? this.props.group.id : 0;

                result.form.forEach(field => {
                    if (preserveData && !field.options.forceValue) {
                        data[field.name] = originalData[field.name];
                        field.data = originalData[field.name];
                    } else {
                        data[field.name] = field.data;
                    }

                    if (this.definedHiddenFields.indexOf(field.name) > -1 && field.type === 'hidden') {
                        hiddenPostFields.push(field.name);
                    }

                    if (field.options.setOnChange) {
                        setOnChange[field.name] = field.options.setOnChange;
                    }

                    if (field.options.patientLink) {
                        patientLinkFields[field.name] = field.options.patientLink;
                    }

                    if (field.options.patientData) {
                        patientDataFields[field.name] = field.options.patientData;
                    }
                    if (field.options.action) {
                        actionFields[field.name] = field.options.action;
                    }

                    formGroups[field.options.form_group ? field.options.form_group : 0].push(field);

                    if (field.name === 'groupId') {
                        groupId = field.data;
                    }
                });

                let submitOnEnter = true;
                if (result.preventSubmitOnEnter) {
                    submitOnEnter = false;
                }

                this.setState({
                    submitUrl: result.url,
                    sections: this.assembleSections(result.form),
                    hiddenPostFields: hiddenPostFields,
                    patientLinkFields: patientLinkFields,
                    patientDataFields: patientDataFields,
                    actionFields: actionFields,
                    formGroups: formGroups,
                    data: data,
                    setOnChange: setOnChange,
                    loading: false,
                    extraLinks: result.extraLinks ? result.extraLinks : [],
                    result: result,
                    errors: result.errors && Object.entries(result.errors).length > 0 ? result.errors : [],
                    submitOnEnter: submitOnEnter
                });

                UtilAppointment.bindInputListener(patientLinkFields, patientDataFields, groupId);

                this.askPageLeaveConfirmation(false);
            })
            .catch((error) => {
                if (error) {
                    this.setState({ errors: FormValidator.validateApiFormResponse(error) });
                } else if (error) {
                    UtilNotification.error(error);

                    this.props.history ?
                        this.props.history.push(this.extraProps.returnUrl) :
                        window.location = this.extraProps.returnUrl;
                }
            });
    }

    resetData = (fields) => {
        let data = this.state.data;

        Object.keys(data).forEach(key => {
            fields.forEach(field => {
                if (key.indexOf(field) > -1) {
                    data[key] = null;
                }
            });
        });

        this.setState({ data: data });
    }

    resetError = (field) => this.setState({
        errors: {
            ...this.state.errors,
            [field.name]: { 'custom': null }
        }
    });

    resetErrors = () => this.setState({ errors: {} });

    assembleSections = (fields) => {
        let sections = [];

        fields.forEach(field => {
            let section = field.options.section ? field.options.section : 'undefined';
            if (!(section in sections)) {
                sections[section] = { 0: [] };
            }

            let formGroup = 'form_group' in field.options ? field.options.form_group : 'last';
            if (!(formGroup in sections[section])) {
                sections[section][formGroup] = [];
            }

            sections[section][formGroup].push(field);
        });

        Object.keys(sections).forEach(sectionKey => {
            Object.keys(sections[sectionKey]).forEach(formGroupId => {
                let items = {};
                let groupsProcessed = {};

                sections[sectionKey][formGroupId].forEach((field, i) => {
                    let group = field.options.group ? field.options.group : field.name;
                    let index = Object.keys(groupsProcessed).find(key => groupsProcessed[key] === group);

                    if (index === undefined) {
                        groupsProcessed[i] = group;
                        index = i;
                    }

                    if (!(index in items)) {
                        items[index] = [];
                    }

                    items[index].push(field);
                })

                sections[sectionKey][formGroupId] = Object.values(items);
            });
        });

        return sections;
    }

    showField = (field) => {
        const { options } = field;

        if (!options || !(options.visible || options.visibleNot)) {
            return true;
        }

        const { data } = this.state;

        let showField = true;

        if (options.visible) {
            Object.keys(options.visible).forEach(field => {
                let value = options.visible[field];
                if (Array.isArray(value)) {
                    if (!UtilArray.inArray(value, data[field])) {
                        showField = false;
                    }
                    /*if (value.indexOf(data[field]) < 0) {
                        showField = false;
                    }*/
                } else if (field in data && String(data[field]) !== String(value)) {
                    showField = false;
                }
            });
        }

        if (options.visibleNot) {
            Object.entries(options.visibleNot).forEach(([name, value], i) => {
                if (Array.isArray(value)) {
                    if (value.indexOf(data[name]) >= 0) {
                        showField = false;
                    }
                } else if (name in data && String(data[name]) === String(value)) {
                    showField = false;
                }
            });
        }

        return showField;
    }

    getHiddenFieldToSet = (changedField) => {
        return this.state.setOnChange[changedField] ? this.state.setOnChange[changedField] : false;
    }

    askPageLeaveConfirmation = (askConfirmation = false) => {
        if (this.routeLeavingGuard) {
            this.routeLeavingGuard.setConfirmedNavigation(!askConfirmation);
        }
    }

    bindNationalInsuranceNumberListeners = () => {
        if (this._nationalInsuranceNumberBinded) return;

        let insuranceNumberInputEl = document.getElementById('nationalInsuranceNumber');

        if (!insuranceNumberInputEl) return;

        if (insuranceNumberInputEl.getAttribute('listener') !== true) {
            insuranceNumberInputEl.addEventListener('keyup', (e) => this.handleNationalInscuranceNumberInput(e));
            insuranceNumberInputEl.addEventListener('input', (e) => this.handleNationalInscuranceNumberInput(e));

            insuranceNumberInputEl.setAttribute('listener', true);
        }

        this._nationalInsuranceNumberBinded = true;
    }

    handleNationalInscuranceNumberInput = (e) => {
        let insuranceNumber = e.target.value.replace(/\.|_|-/g, '');
        let insuranceNumberValidation = this.IsRRNoValid(insuranceNumber);
        if (!insuranceNumberValidation) return;

        let bDate = (insuranceNumberValidation === 2 ? '20' : '19') + insuranceNumber.substring(0, 6);
        this.handleManualChangeValue('birthdate', UtilDate.create(bDate, 'YYYYMMDD').format('YYYY-MM-DD'));
        //document.dispatchEvent(new CustomEvent('birthdateChanged', { detail: UtilDate.create(bDate, 'YYYYMMDD').format('YYYY-MM-DD') }));
        UtilEvent.trigger('birthdateChanged', UtilDate.create(bDate, 'YYYYMMDD').format('YYYY-MM-DD'));

        let gender = insuranceNumber.substring(6, 3) % 2 === 0 ? 'v' : 'm';
        this.handleManualChangeValue('gender', gender);
        //document.dispatchEvent(new CustomEvent('genderChanged', { detail: gender }));
        UtilEvent.trigger('choiceChanged', { value: gender });
    }

    IsRRNoValid = (n) => {
        // RR numbers need to be 11 chars long
        if (n.length !== 11) return false;

        var checkDigit = parseInt(n.substring(n.length - 2, 2));
        var modFunction = function (nr) { return 97 - (nr % 97); };
        var nrToCheck = parseInt(n.substring(0, 9));

        // first check without 2
        if (modFunction(nrToCheck) === checkDigit) return 1;

        // then check with 2 appended for y2k+ births
        nrToCheck = parseInt('2' + n.substring(0, 9));

        return (modFunction(nrToCheck) === checkDigit) ? 2 : false;
    }

    getData = () => this.state.data;

    setData = (data) => {
        if (!this._isMounted) return;
        this.setState({ data: data });
    }

    getFormField = (fieldName) => {
        if (!this.state.result.form) return null;
        return this.state.result.form.filter(f => f.name === '' + fieldName)[0];
    }

    handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            UtilAppointment.resetAutoSuggest();
        }
    }
}

Form.defaultProps = {
    submitBtnTitle: 'common:Save',
    submitBtnIcon: 'save',
    successMessage: 'common:successMessage'
}

const mapStateToProps = state => ({ client: state.client ? state.client.active : null, group: state.group });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Form);