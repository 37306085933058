import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

const ButtonLink = props => (
    <Button id={props.id} color={props.color} size={props.size} tag={props.tag} to={props.to} className={'btn-labeled ' + props.className}>
        <span className="btn-label"><i className={'fa fa-' + props.icon} aria-hidden></i></span>
        {props.children}
    </Button>
)

ButtonLink.defaultProps = {
    tag: Link,
    color: 'default',
    size: 'md',
    disabled: false
}

export default ButtonLink;