import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import ReactDataGrid from 'react-data-grid';
import moment from 'moment';
import { ApiTaskService } from '../../services/api/task.service';
import ModalBody from './partials/ModalBody';

class ModalAppointmentLog extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;

        this._columns = [{
            key: 'date',
            name: t('tools.task:logs:modal:table.date'),
            width: 175,
            resizable: true
        },
        {
            key: 'type',
            name: t('tools.task:logs:modal:table.type'),
            width: 200,
            resizable: true
        },
        {
            key: 'assigned',
            name: t('tools.task:logs:modal:table.assigned'),
            width: 200,
            resizable: true
        },
        {
            key: 'message',
            name: t('tools.task:logs:modal:table.message'),
            resizable: true
        }];

        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            modal: true,
            logs: []
        };
    }

    rowGetter = (i) => this.state.rows[i]

    handleGridSort = (sortColumn, sortDirection) => {
        const comparer = (a, b) => {
            if (sortDirection === 'ASC') {
                return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
            }
        };

        const rows = sortDirection === 'NONE' ? this.state.originalRows.slice(0) : this.state.rows.sort(comparer);

        this.setState({ rows });
    };

    createRows = () => this.state.logs.map(log => {
        switch (log.type) {
            case 'status':
                log.message = t('tools.task:statuses:text:' + log.message);
                break;
            case 'priority':
                log.message = t('tools.task:priorities:text:' + log.message);
                break;
            default:
                break;
        }

        return {
            date: moment(log.datetime).format('ddd DD/MM/YYYY HH:mm:ss'),
            type: t('tools.task:logs:type:' + log.type),
            assigned: log.assignedBy,
            message: log.message
        }
    });

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.load();
    }

    componentWillReceiveProps() {
        this._forceUpdate = true;
        this.setState(this.initModal());
    }

    componentDidUpdate() {
        if (this.state.logs.length <= 0 && this._forceUpdate) {
            this._forceUpdate = false;
            this.load();
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        let originalRows = this.createRows();
        let rows = originalRows.slice(0);

        return (
            <Modal className="statuslogs" size="xl" isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}><strong>{t('tools.task:logs:modal:title')}</strong></ModalHeader>
                <ModalBody loading={this.state.loading}>
                    <div style={{ maxWidth: '100%' }}>
                        <ReactDataGrid
                            onGridSort={this.handleGridSort}
                            columns={this._columns}
                            // rowGetter={this.rowGetter}
                            rowGetter={(i) => rows[i]}
                            rowsCount={rows.length}
                            minHeight={250}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.onToggle}>{t('common:Close')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    fire = () => this.setState({ modal: true });
    onToggle = () => this.setState({ modal: !this.state.modal });

    load = () => ApiTaskService.logs(this.props.task).then(results => this.setState({ loading: false, logs: results }))
}

export default ModalAppointmentLog;