import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { connect } from 'react-redux';
import routes from '../../../routing/routes';
import SidebarGroup from './SidebarGroup';
import SidebarClient from './SidebarClient';

class SidebarUserBlock extends Component {
    render() {
        if (!this.props.client) return null;

        return (
            <Collapse id="user-block" isOpen={true}>
                <div>
                    <div className="item user-block">
                        {/* User picture */}
                        <div className="user-block-picture">
                            <div className="user-block-status">
                                <img className="img-thumbnail rounded-circle" src={this.props.client.image_small ? this.props.client.image_small : 'images/dummy.png'} alt="Avatar" width="60" height="60" />
                            </div>
                        </div>
                        {/* Name and Job */}
                        <div className="user-block-info">
                            <Link to={{ pathname: reverse(routes.settings.agenda.profile) }} className="user-block-name">
                                {this.props.client.fullName}
                            </Link>

                            <SidebarGroup {...this.props} />
                            {(typeof this.props.client.isSecretary !== undefined) && this.props.client.isSecretary && <SidebarClient {...this.props} />}
                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool
};

const mapStateToProps = state => ({ showUserBlock: state.settings.showUserBlock, client: state.client ? state.client.logged_in : null, group: state.group })

export default connect(mapStateToProps)(SidebarUserBlock);