import React from 'react';
import { t } from 'i18next';
import { Menu, Item, Submenu, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import moment from 'moment';
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import { ApiAppointmentService } from '../../services/api/appointment.service';
import { ApiScheduleService } from '../../services/api/schedule.service';
import { UtilArray, UtilClient, UtilColor, UtilException, UtilNotification, UtilUrl } from '../../utils';
import { ApiExceptionService } from '../../services/api/exception.service';

class ContextMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: props.event,
            category: props.category,
            hasAccessToSettings: UtilClient.hasAccessToSettings()
        }
    }

    render() {
        let { hasAccessToSettings } = this.state;

        return (
            <Menu id={this.props.menuId} className="context-menu">
                {this.renderAddHomeVisitItem()}
                {this.renderchangeCategoryItem()}
                {hasAccessToSettings && this.renderRemovePublicHolidayItem()}
                {this.renderBlockSlotItem()}
                {this.renderRemoveExceptionItem()}
                {hasAccessToSettings && this.renderRemoveDayAppointmentItem()}
                {hasAccessToSettings && this.renderRemoveHolidayItem()}
                {this.renderAppointmentActionsItem()}
                {this.renderRemoveNoteItem()}
                {hasAccessToSettings && this.renderPriorityBreakhourItem()}
                {hasAccessToSettings && this.renderShowHistoryItem()}
            </Menu>
        );
    }

    renderAddHomeVisitItem = () => {
        const { url } = this.state.event;
        const { addUrl, full, isHomeVisit } = this.state.event.extendedProps;

        if (!isHomeVisit || (!addUrl && !url)) return;

        return (
            <Item onClick={() => { this.props.history.push(addUrl ? addUrl : url) }} disabled={full}>
                <span className="context-menu-item"><i className="fa fa-plus"></i></span>
                {t('agenda.myagenda:contextMenu:bookHomeVisit')}
            </Item>
        );
    }

    renderchangeCategoryItem = () => {
        const { blocked, blockReason, dayAppointment, isHomeVisit, slotId } = this.state.event.extendedProps;

        if (!slotId || isHomeVisit || dayAppointment || (blocked && blockReason !== 'internal-not-bookable')) return;

        let clientContext = this.getClientSelectedContext();

        let categoriesPerGroup = {};
        UtilArray.asort(this.props.categories, 'position').forEach(category => {
            if (clientContext.internalOnly && (category.bookable || !category.bookableInternal)) return;
            if (!(category.group.name in categoriesPerGroup)) {
                categoriesPerGroup[category.group.name] = [];
            }

            categoriesPerGroup[category.group.name].push(category);
        });

        let preferredCategories = null;

        if (clientContext) {
            if (clientContext.settings.preferredCategories) {
                preferredCategories = clientContext.settings.preferredCategories;
            }
        } else {
            preferredCategories = this.props.settings.preferredCategories
        }

        return (
            <Submenu label={t('agenda.myagenda:contextMenu:changeCategory')}>
                {Object.keys(categoriesPerGroup).length === 1 ?
                    this.renderchangeCategoryItems(Object.values(categoriesPerGroup)[0], preferredCategories, clientContext)
                    :
                    Object.keys(categoriesPerGroup).map(group => {
                        return <React.Fragment key={group}>
                            <Item disabled>{group}</Item>
                            {this.renderchangeCategoryItems(categoriesPerGroup[group], preferredCategories, clientContext)}
                        </React.Fragment>
                    })
                }
            </Submenu>
        );
    }

    renderchangeCategoryItems = (categories, preferredCategories, client) => categories.map(category => {
        if (preferredCategories && preferredCategories.length > 0 && preferredCategories.indexOf(category.id) < 0) return null;
        if (client.internalOnly && (category.bookable || !category.bookableInternal)) return null;

        return (
            <Item key={category.id} onClick={() => this.onCategoryChange(category.id)} style={{ backgroundColor: category.freeSlotBackground }}>
                <span style={{ color: UtilColor.contrastBlackOrWhite(category.freeSlotBackground) }}>{category.name}</span>
            </Item>
        )
    });

    renderRemovePublicHolidayItem = () => {
        if (this.state.event.extendedProps.appointments.length > 0 || !this.state.event.extendedProps.publicHoliday) return;

        return (
            <Item onClick={() => this.props.removeHolidayModal.fire({ id: this.state.event.extendedProps.publicHoliday })}>
                {t('agenda.myagenda:contextMenu:removePublicHoliday')}
            </Item>
        );
    }

    renderBlockSlotItem = () => {
        const { appointments, dayAppointment, publicHoliday, blocked, slotId, isNote, isCanceled } = this.state.event.extendedProps;

        if (appointments.length > 0 || dayAppointment || publicHoliday || isNote || isCanceled) return;

        let isExceptionSlot = slotId && slotId.indexOf('ex_') > -1;

        return (
            <Item onClick={this.handleBlock}>
                <span className="context-menu-item"><i className="fa fa-ban"></i></span>
                {blocked ?
                    (isExceptionSlot ? t('agenda.myagenda:contextMenu:deblock-exception') : t('agenda.myagenda:contextMenu:deblock')) :
                    (isExceptionSlot ? t('agenda.myagenda:contextMenu:block-exception') : t('agenda.myagenda:contextMenu:block'))
                }
            </Item>
        )
    }

    renderRemoveExceptionItem = () => {
        const { isNote, slotId } = this.state.event.extendedProps;

        if (!slotId || slotId.indexOf('ex_') < 0 || isNote) return;

        return (
            <React.Fragment>
                <Item onClick={() => this.props.history.push(reverse(routes.settings.exceptions.edit, { id: slotId.replace(/ex_/, '') }))}>
                    <span className="context-menu-item"></span>
                    {t('agenda.myagenda:contextMenu:updateException')}
                </Item>
                <Item onClick={() => this.props.removeExceptionModal.fire({ id: slotId }, { redirectUrl: this.generateRedirectLink(this.state.event.start, this.props.client) })}>
                    <span className="context-menu-item"></span>
                    {t('agenda.myagenda:contextMenu:removeException')}
                </Item>
            </React.Fragment>
        )
    }

    renderRemoveDayAppointmentItem = () => {
        const { dayAppointment } = this.state.event.extendedProps;

        if (!dayAppointment || dayAppointment.isGhost) return;

        return (
            <Submenu label={t('agenda.myagenda:contextMenu:appointment')}>
                <Item onClick={() => this.props.removeDayAppointmentModal.fire({ id: dayAppointment.id })}>{t('common:Remove')}</Item>
            </Submenu>
        )
    }

    renderRemoveHolidayItem = () => {
        const { dayAppointment } = this.state.event.extendedProps;

        if (!dayAppointment || !dayAppointment.publicHoliday) return;

        return (
            <Item onClick={() => this.props.removeHolidayModal.fire({ id: dayAppointment.publicHoliday })}>
                {t('agenda.myagenda:contextMenu:removePublicHoliday')}
            </Item>
        )
    }

    renderAppointmentActionsItem = () => {
        const { appointments } = this.state.event.extendedProps;

        if (appointments.length <= 0) return;

        const { category } = this.state;
        const { subcategories, statuses } = this.props.schemaData;

        let categories = UtilArray.asort(this.props.schemaData.categories, 'position');

        let statusesSorted = Object.keys(statuses).sort(function (keyA, keyB) {
            return statuses[keyA].position - statuses[keyB].position;
        });

        let preferredSubcategories = null;
        let clientContext = this.getClientSelectedContext();
        if (clientContext) {
            if (clientContext.settings.preferredSubcategories) {
                preferredSubcategories = clientContext.settings.preferredSubcategories;
            }
        } else {
            preferredSubcategories = this.props.settings.preferredSubcategories
        }

        let categorySubcategories = UtilArray.asort(category.subCategories, 'position').map(subcategory => subcategory.id);

        return (
            <React.Fragment>
                {appointments.map((appointment, i) => {
                    let redirectParams = { date: moment(appointment.start).format('YYYY-MM-DD-HH-mm') };
                    let route = routes.agenda.group;
                    if (this.props.subgroup) {
                        route = routes.agenda.subgroup;
                        redirectParams.subgroup = this.props.subgroup.id;
                    } else if (!this.props.groupOverview) {
                        route = routes.agenda.client;
                        redirectParams.client = appointment.clientId;
                    }

                    let redirectUrl = UtilUrl.generate(route, redirectParams);

                    let appointmentClient = this.props.group.clients.filter(c => c.id === appointments[0].clientId)[0];
                    let cancelAppointmentsWithReason = appointmentClient ? appointmentClient.settings.cancelAppointmentsWithReason : false;
                    
                    return (
                        <React.Fragment key={i}>
                            <Item disabled>{appointment.patient.name}</Item>

                            {/* Appointment status start */}
                            <Submenu label={t('agenda.myagenda:contextMenu:status')}>
                                <Item onClick={() => this.onQuickChange('status', appointment.id, null)}>{t('common:None')}</Item>
                                {statusesSorted.map((key, i) => {
                                    if (!(key in statuses)) return null;

                                    let status = statuses[key];
                                    if (status.groupId !== appointment.groupId) return null;

                                    return <Item key={i} onClick={() => this.onQuickChange('status', appointment.id, key)} style={{ backgroundColor: status.backgroundColor }}>
                                        <span style={{ color: status.textColor }}>{status.title}</span>
                                    </Item>
                                })}
                            </Submenu>
                            {/* Appointment status end */}

                            {/* Appointment category start  */}
                            <Submenu label={t('agenda.myagenda:contextMenu:category')}>
                                {categories.map(category => {
                                    if (appointment.groupId !== category.group.id) return null;

                                    return <Item key={category.id} onClick={() => this.onQuickChange('categoryId', appointment.id, category.id)} style={{ backgroundColor: category.freeSlotBackground }}>
                                        <span style={{ color: UtilColor.contrastBlackOrWhite(category.freeSlotBackground) }}>{category.name}</span>
                                    </Item>
                                })}
                            </Submenu>
                            {/* Appointment category end */}

                            {/* Appointment subcategory start */}
                            <Submenu label={t('agenda.myagenda:contextMenu:appointmentType')}>
                                <Item onClick={() => this.onQuickChange('subCategoryId', appointment.id, null)}>{t('common:None')}</Item>
                                {categorySubcategories.map(key => {
                                    if (!(key in subcategories)) return null;
                                    let subcategory = subcategories[key];
                                    if (preferredSubcategories && preferredSubcategories.length > 0 && preferredSubcategories.indexOf(subcategory.id) < 0) return null;

                                    return <Item key={key} onClick={() => this.onQuickChange('subCategoryId', appointment.id, key)} style={{ backgroundColor: subcategory.freeSlotBackground }}>
                                        <span style={{ color: UtilColor.contrastBlackOrWhite(subcategory.freeSlotBackground) }}>{subcategory.name} {/*({subcategory.minutesPerSlot}')*/}</span>
                                    </Item>
                                })}
                            </Submenu>
                            {/* Appointment subcategory end */}

                            <Submenu key={i} label={t('agenda.appointment:more-actions')}>
                                <Item onClick={() => this.props.parent.renderAppointmentModal(null, appointment)}>
                                    {t('agenda.appointment:details')}
                                </Item>
                                {appointment.repeatId && this.props.parent.renderRepeatedAppointmentModal && (
                                    <Item onClick={() => this.props.parent.renderRepeatedAppointmentModal({ repeat: appointment.repeatId })}>
                                        {t('agenda.appointment:show-repeating')}
                                    </Item>
                                )}
                                <Item onClick={() => this.handleAppointmentAction('copy', appointment)}>
                                    {t('agenda.appointment:copy')}
                                </Item>
                                <Item onClick={() => this.handleAppointmentAction('move', appointment)}>
                                    {t('agenda.appointment:move')}
                                </Item>
                                <Item onClick={() => this.props.history.push(reverse(routes.appointments.edit, { client: appointment.clientId, id: appointment.id }))}>
                                    {t('common:Edit')}
                                </Item>
                                {!appointment.repeatId && (
                                    <>
                                        {cancelAppointmentsWithReason && (<Item onClick={() => this.props.modalAppointmentCancel.fire({ id: appointment.id }, { redirectUrl: redirectUrl })}>{t('common:Cancel-with-reason')}</Item>)}
                                        <Item onClick={() => this.props.removeModal.fire({ id: appointment.id }, { redirectUrl: redirectUrl })}>{t('common:Remove')}</Item>
                                    </>
                                )}

                                {appointment.repeatId && (
                                    <React.Fragment>
                                        {cancelAppointmentsWithReason && (<Item onClick={() => this.props.modalAppointmentCancel.fire({ id: appointment.id, repeatId: appointment.repeatId, dateFrom: appointment.start }, { redirectUrl: redirectUrl })}>{t('common:Cancel-with-reason')}</Item>)}
                                        <Item onClick={() => this.props.removeModal.fire({ id: appointment.id, repeatId: appointment.repeatId, dateFrom: appointment.start }, { redirectUrl: redirectUrl })}>{t('common:Remove')}</Item>
                                    </React.Fragment>
                                )}
                                <Item onClick={() => { window.open(reverse(routes.appointments.print, { id: appointment.id }), '_blank') }}>{t('common:Print')}</Item>
                            </Submenu>
                        </React.Fragment>
                    )
                })}

                <Separator />
            </React.Fragment>
        )
    }

    renderRemoveNoteItem = () => {
        const { isNote, note } = this.state.event.extendedProps;

        if (!isNote) return;

        return (
            <React.Fragment>
                <Item onClick={() => this.props.removeNoteModal.fire({ id: note.id, all: false }, { redirectUrl: this.generateRedirectLink(note.start, this.props.client) })}>
                    <span className="context-menu-item"><i className="fa fa-trash"></i></span>
                    {t('common:Remove')}
                </Item>
                <Item onClick={() => this.props.removeNoteModal.fire({ id: note.id, all: true }, { redirectUrl: this.generateRedirectLink(note.start, this.props.client) })}>
                    <span className="context-menu-item"><i className="fa fa-trash"></i></span>
                    {t('agenda.note:removeAllAgendas')}
                </Item>
            </React.Fragment>
        )
    }

    renderShowHistoryItem = () => (
        <Item onClick={this.showHistory}>
            <span className="context-menu-item"><i className="fa fa-history"></i></span>
            {t('agenda.myagenda:contextMenu:history')}
        </Item>
    );

    renderPriorityBreakhourItem = () => {
        const { slotId } = this.state.event.extendedProps;

        if (!slotId || (isNaN(slotId) && !slotId.includes('ex_'))) return;

        return (
            <Submenu label={t('agenda.myagenda:contextMenu:settings-slot')}>
                <Item disabled>{t('agenda.myagenda:contextMenu:settings-slot-breakhour')}</Item>
                <Item onClick={() => this.onPriorityBreakhourClick(true)}>{t('common:Yes')}</Item>
                <Item onClick={() => this.onPriorityBreakhourClick(false)}>{t('common:No')}</Item>

                <Item disabled>{t('agenda.myagenda:contextMenu:settings-slot-priority')}</Item>
                {this.renderPriorityItems()}
            </Submenu>
        );
    }

    renderPriorityItems = () => UtilException.range().map(i => <Item key={i} onClick={() => this.onPriorityBreakhourClick(null, i)}>{i}</Item>)

    onPriorityBreakhourClick = (newBreakhour = null, newPriority = null) => {
        const { event } = this.state;
        let { category, isBreakHour, slotId, resourceId, priority } = event.extendedProps;

        newPriority = newPriority !== null ? newPriority : priority;
        newBreakhour = newBreakhour !== null ? newBreakhour : isBreakHour;

        if (newBreakhour === isBreakHour && newPriority === priority) return;

        let data = {
            slot: slotId,
            start: moment(event.start).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(event.end).format('YYYY-MM-DD HH:mm:ss'),
            client: resourceId,
            priority: newPriority,
            breakhour: newBreakhour,
            category: category
        };

        let existingException = slotId && slotId.includes('ex_');
        ApiExceptionService.createSimple(data)
            .then(() => this.props.onResponse('success', existingException ? t('settings.exception:edit.successfull') : t('settings.exception:add.successfull')));
    }

    handleBlock = () => {
        const block = {
            startDate: moment(this.state.event.start).format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(this.state.event.end).format('YYYY-MM-DD HH:mm:ss'),
            block: !this.state.event.extendedProps.blocked,
            slotId: this.state.event.extendedProps.slotId
        };

        ApiScheduleService.saveBlocks([block], this.state.event.extendedProps.resourceId ? this.state.event.extendedProps.resourceId : this.props.client)
            .then(() => this.props.onResponse('success', block.block ? t('agenda.myagenda:slotBlocked') : t('agenda.myagenda:slotDeblocked')));
    }

    showHistory = () => this.props.history.push({
        pathname: UtilUrl.generate(routes.agenda.history, {
            date: moment(this.state.event.start).format('YYYY-MM-DD'),
            start: moment(this.state.event.start).format('HH:mm'),
            end: this.state.event.end ? moment(this.state.event.end).format('HH:mm') : '23:59',
            client: this.props.client
        })
    });

    onAppointmentCategoryChange = (appointments, categoryId) => appointments.forEach((appointment) => {
        this.onQuickChange('categoryId', appointment.id, categoryId)
    });

    onQuickChange = (type, appointmentId, newValue) =>
        ApiAppointmentService.quickUpdate(appointmentId, { update: type, newValue: newValue })
            .then(() => this.props.onResponse('success', t('agenda.appointment:edit.successfull')))
            .catch(error => UtilNotification.toastError(error));

    handleAppointmentAction = (action, appointment) => {
        this.props.actions.changeSetting('modus', action);
        this.props.actions.changeSetting('modusParams', { appointment: appointment.id, hasFollowUps: appointment.type === 3 });
        this.props.actions.setPatient(null);

        this.props.history.push(this.generateRedirectLink(appointment.start, appointment.clientId));
    }

    onCategoryChange = (categoryId) => ApiExceptionService.updateSlotCategory({
        slotId: this.state.event.extendedProps.slotId,
        category: categoryId,
        start: moment(this.state.event.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(this.state.event.end).format('YYYY-MM-DD HH:mm:ss'),
        client: this.state.event.extendedProps.resourceId,
        forceTiming: false
    })
        .then(response => this.props.onResponse(response.status, t('agenda.myagenda:categoryChanged')))
        .catch(error => this.props.onResponse('fail', error.message));

    generateRedirectLink = (date, clientId = null) => {
        let params = { date: moment(date).format('YYYY-MM-DD-HH-mm') };
        let route = routes.agenda.group;

        if (this.props.subgroup) {
            route = routes.agenda.subgroup;
            params.subgroup = this.props.subgroup.id;
        } else if (!this.props.groupOverview) {
            if (clientId) params.client = clientId;
            route = routes.agenda.client;
        }

        return UtilUrl.generate(route, params);
    }

    getClientSelectedContext = () => this.props.group.clients.filter(item => item.id === this.state.event.extendedProps.resourceId)[0];
}

export default ContextMenu;