import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ApiBingliService } from '../../services/api/bingli.service';
import Spinner from '../reactstrap/Spinner';
import ButtonExternalLink from '../buttons/ButtonExternalLink';

class ModalBingliSummary extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;
        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            summaryLink: null,
            interviewLink: null,
            message: null,
            modal: true
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.loadData();
    }

    componentWillReceiveProps() {
        this.setState(this.initModal());
        this._forceUpdate = true;
    }

    componentDidUpdate() {
        if (!this.state.interviewLink && !this.state.message && this._forceUpdate) {
            this._forceUpdate = false;
            this.loadData();
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        const { loading, message, summaryLink, interviewLink } = this.state;

        const interviewButton = (
            <ButtonExternalLink href={interviewLink} target="_blank" className="ml-2">
                {t('agenda.appointment:bingli.modal.button.interviewText')}
            </ButtonExternalLink>
        );

        return (
            <Modal size="xxl" isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}>
                    <strong>{t('agenda.appointment:bingli.modal.title')}</strong>
                    {interviewLink && interviewButton}
                </ModalHeader>
                <ModalBody>
                    <Spinner visble={loading} />
                    {!loading && message && (<span>{message.join(', ')}</span>)}
                    {!loading && summaryLink && (
                        <iframe
                            src={summaryLink}
                            // src="https://cdne-patient-acc.azureedge.net/#/interview/summary/c0ce740a-bbd0-4d52-949f-d727631a8d02"
                            style={{ minWidth: '100%', minHeight: '700px', border: 0 }}
                            title={t('agenda.appointment:bingli.modal.title')}
                            sandbox="allow-scripts allow-same-origin"
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    {interviewLink && interviewButton}
                    <Button color="info" onClick={this.onToggle}>{t('common:Close')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    fire = () => this.setState({ modal: true });
    onToggle = () => this.setState({ modal: !this.state.modal });

    loadData = () => ApiBingliService.getSummary(this.props.appointmentId)
        .then(result => this.setState({
            summaryLink: result.summaryLink ? result.summaryLink : null,
            interviewLink: result.doctorInterviewLink ? result.doctorInterviewLink : null,
            message: result.message ? result.message : null,
            loading: false
        }));
}

export default ModalBingliSummary;