import React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { AlertInfo } from '../alert';

class FormTab extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeTab: props.layout.defaultActive
        }
    }

    componentDidUpdate() {
        this.showErrorBadges();
    }

    render() {
        const { tabs } = this.props.layout;

        return (
            <React.Fragment>
                <Nav tabs justified>
                    {tabs.map(tab => (
                        <NavItem key={'nav-item-' + tab.name}>
                            <NavLink
                                id={'tab-' + tab.name}
                                className={this.state.activeTab === tab.name ? 'active' : ''}
                                onClick={() => this.toggleTab(tab.name)}
                                data-tab={tab.name}
                            >
                                {tab.title}
                                <span id={'tab-error-indicator-' + tab.name} className="indicator text-danger float-right hidden">1</span>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={this.state.activeTab} className="bg-white">
                    {tabs.map(tab => (
                        <TabPane tabId={tab.name} key={'tab-pane-' + tab.name} data-tab={tab.name}>
                            {tab.notification && (<AlertInfo message={tab.notification} />)}
                            {this.props.renderFields(tab.name)}
                        </TabPane>
                    ))}
                </TabContent>
            </React.Fragment>
        )
    }

    toggleTab = tab => this.setState({ activeTab: tab });

    showErrorBadges = () => [].forEach.call(document.getElementsByClassName('tab-pane'), (el) => {
        let invalidEls = el.getElementsByClassName('is-invalid');
        if (invalidEls.length <= 0) return;

        let indicator = document.getElementById('tab-error-indicator-' + el.dataset.tab);
        if (!indicator) return;

        indicator.innerText = invalidEls.length;
        indicator.classList.remove('hidden');
    });

    renderTabPanes = (tabs) => {
        let tabPanes = [];

        tabs.forEach((tab, i) => {
            tabPanes.push(
                <TabPane tabId={tab.name} key={i} data-tab={i}>
                    {this.props.renderFields(tab.name)}
                </TabPane>
            );
        });

        return tabPanes;
    }

    toggleTab = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
}

export default FormTab;