import React from 'react';
import { t } from 'i18next';
import { Button, Modal, Nav, NavItem, NavLink, TabContent, Table, TabPane } from 'reactstrap';
import Form from '../form/Form';
import { ButtonSave } from '../buttons';
import ModalHeader from './partials/ModalHeader';
import { ApiPatientService } from '../../services/api/patient.service';
import TableBody from '../table/TableBody';
import TableHead from '../table/TableHead';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import { ButtonActionRemove } from '../buttons';
import { ModalRemove } from '../modals';
import { UtilNotification } from '../../utils';

class ModalPatientFilter extends React.Component {
    constructor(props) {
        super(props);
        
        this._isMounted = false;

        this._removeFilterModalProps = {
            removeUrl: 'client/patient/patient/remove-filter', // API endpoint URL
            successMessage: t('common:remove.success'),
            // redirectUrl: props.match.url,
            actionAfterRemove: this.actionAfterRemove,
            text: t('common:remove.confirmation'),
            onRef: (ref) => this._modalFilterRemove = ref,
            ///...props
        }

        this.state = {
            createOnly: props.createOnly,
            activeTab: 'filter-existing',
            activeTabNew: 'filters',
            modal: false,
            filter: null,
            loading: true,
            existingFilters: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.loadFilters();

        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                    <ModalHeader title={t('patients.patient:filter:title')} text={t('patients.patient:filter:text')} className="border-bottom-0" onClose={this.onToggle} />

                    <Nav tabs justified>
                        {!this.state.createOnly && (
                            <NavItem key="filter-existing">
                                <NavLink id="tab-filter-existing" data-tab="filter-existing"
                                    className={this.state.activeTab === 'filter-existing' ? 'active' : ''}
                                    onClick={() => this.toggleTab('filter-existing')}
                                >
                                    {t('patients.patient:filter:tab:existing')}
                                </NavLink>
                            </NavItem>
                        )}
                        <NavItem key="filter-new">
                            <NavLink id="tab-filter-new" data-tab="filter-new"
                                className={this.state.activeTab === 'filter-new' ? 'active' : ''}
                                onClick={() => this.toggleTab('filter-new')}
                            >
                                {t('patients.patient:filter:tab:new')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="bg-white">
                        <TabPane tabId="filter-existing" key="filter-existing" data-tab="filter-existing">
                            {this.renderExistingTab()}
                        </TabPane>

                        <TabPane tabId="filter-new" key="filter-new" data-tab="filter-new">
                            {this.renderNewTab()}
                        </TabPane>
                    </TabContent>
                </Modal >
                <ModalRemove {...this._removeFilterModalProps} />
            </>
        );
    }

    renderExistingTab = () => {
        return (
            <Table striped bordered responsive>
                <TableHead>
                    <tr>
                        <th>{t('patients.patient:filter:existing:table:title')}</th>
                        <th width="150">{t('common:actions')}</th>
                    </tr>
                </TableHead>
                <TableBody className="sortable" loading={this.state.loading} cols={12}>
                    {!this.state.loading && this.state.existingFilters.map((item, i) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    {item.title}<br />
                                    {item.summary && <small>{item.summary}</small>}
                                </td>
                                <td className='text-right'>
                                    <Button color="default" size="sm" id={'buttonSearch' + item.id} onClick={() => this.filter(item)}>
                                        <i className="fas fa-search" aria-hidden></i>
                                    </Button>
                                    <UncontrolledTooltip target={'buttonSearch' + item.id}>
                                        {t('patients.patient:filter:btn.search')}
                                    </UncontrolledTooltip>

                                    <ButtonActionRemove id={item.id} modalRemove={this._modalFilterRemove} />
                                </td>
                            </tr>
                        );
                    })}
                </TableBody>
            </Table >
        );
    }

    renderNewTab = () => {
        let formProps = {
            type: 'filter',
            url: 'client/patient/patient/get-form',
            requestData: {
                groupId: this.props.group,
                filter: this.state.filter ? this.state.filter.filters : null
            },
            id: this.state.filter ? this.state.filter.id : null,
            submitBtnTitle: this.state.createOnly ? t('common:Save') : t('patients.patient:filter:btn.search-save'),
            preventHeaderManipulation: true,
            onFakeSubmit: this.onFakeSubmit,
            layout: {
                type: 'tab',
                defaultActive: this.state.activeTabNew,
                tabs: [
                    { name: 'filters', title: t('patients.patient:filter:tab:filters') },
                    { name: 'content', title: t('patients.patient:filter:tab:content') }
                ]
            }
        };

        return <Form {...formProps} view={this} onRef={ref => (this._formRef = ref)} />
    }

    renderFormButtons = (disabled = false) => {
        if (!this._formRef || this.state.createOnly) return;

        return (
            <ButtonSave type="button" disabled={disabled} name="search" icon="search" className="ml-1" onClick={() => this._formRef.onSubmit('fake')}>
                {t('patients.patient:filter:btn.search')}
            </ButtonSave>
        );
    }

    toggleTab = tab => this.setState({ activeTab: tab });
    onToggle = () => this.show(!this.state.modal);
    show = (show = true, filter = null, tab = 'filter-existing', tabNew = 'filters') => this.setState({
        modal: show,
        filter: filter,
        activeTab: tab,
        activeTabNew: tabNew
    }, () => {
        if (!filter && tab === 'filter-existing') {
            this.loadFilters();
        }
    });

    onSubmit = (response, data) => {
        if (response.status === 'success') {
            UtilNotification.toastSuccess(t('patients.patient:filter:save.success'));
            this.filter(response.data.filter);
            this.loadFilters();
        }
    }

    onFakeSubmit = (data) => {
        let { filter } = this.state;

        if (!filter) filter = {};

        filter.title = data.title;
        filter.summary = data.summary;
        filter.filters = data;

        this.filter(filter);
    }

    filter = (data) => {
        if (this.props.onSearchClick) {
            this.props.onSearchClick(data);
        }

        this.show(false);
    }

    actionAfterRemove = (id) => {
        this.loadFilters();

        if (this.state.filter && this.state.filter.id === id) {
            if (this.props.onSearchClick) {
                this.props.onSearchClick(null);
            }

            this.setState({ show: false, filter: null }, () => {
                this.show(true, null, 'filter-exiting');
            });
        }
    }

    loadFilters = () => {
        if (!this._isMounted) return;

        this.setState({ loading: true }, () =>
            ApiPatientService.getFilters(this.props.group)
                .then(result => this.setState({ existingFilters: result, loading: false })));
    }
}

ModalPatientFilter.defaultProps = {
    createOnly: false
}

export default ModalPatientFilter;