
export default class GroupSetting {
    agendaShowEmptyAgendas = true;
    phoneSocketChannelId;

    static transform = (data) => {
        let item = new GroupSetting();

        item.agendaShowEmptyAgendas = data.agendaShowEmptyAgendas;
        item.phoneSocketChannelId = data.phoneSocketChannelId;

        return item;
    }
}