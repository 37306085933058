import React from 'react';
import Select, { components } from 'react-select';
import { t } from 'i18next';
import { Button } from 'reactstrap';
import ModalSubgroupSearch from '../../modals/ModalSubgroupSearch';

class ChoicePostalField extends React.Component {
    constructor(props) {
        super(props);

        this._modalProps = {
            group: props.data.groupId,
            onRef: ref => this._modal = ref,
            onConfirm: this.handlePostalSearchConfirm,
            form: props.parent
        }

        this.state = {
            field: props.field,
            options: [],
            selectedOptionMulti: null,
            inputValue: null
        };
    }

    componentWillReceiveProps(newProps) {
        this.assembleOptions(newProps);
    }

    handleChangeSelectMulti = (selectedOptionMulti) => {
        let allSelected = selectedOptionMulti.filter(o => o.value === 'all');
        let allStateSelected = this.state.selectedOptionMulti.filter(o => o.value === 'all');

        if (allSelected.length > 0 && allStateSelected <= 0) { // The 'all' option is selected, remove other elements
            selectedOptionMulti.filter(o => o.value !== 'all').forEach(other => {
                let i = selectedOptionMulti.map(o => o.value).indexOf(other.value);
                selectedOptionMulti.splice(i, 1);
            });
        } else {
            let allIndex = selectedOptionMulti.map(o => o.value).indexOf('all');
            if (selectedOptionMulti.length > 1 && allIndex > -1) {
                selectedOptionMulti.splice(allIndex, 1);
            }
        }

        this.setState({ selectedOptionMulti }, () => {
            let selectedOptions = selectedOptionMulti.map(option => option.value);
            this.props.onChangeValue(this.props.field.name, selectedOptions);
        })
    };

    assembleOptions = (props) => {
        let options = Object.entries(props.field.choices).map(([key, choice]) => {
            return { value: '' + key.replace(/___/g, ''), label: choice };
        });

        let fieldData = props.data[props.field.name];

        let selectedOptionMulti = [];
        fieldData && Object.entries(fieldData).forEach(([index, key]) => {
            selectedOptionMulti = this.assembleSelectedOptionMulti(key, options, selectedOptionMulti);
        });

        this.setState({
            field: props.field,
            options: options,
            selectedOptionMulti: selectedOptionMulti,
        });
    }

    assembleSelectedOptionMulti = (key, options, selectedOptionMulti) => {
        for (let i in options) {
            if (String(key) === options[i].value) {
                selectedOptionMulti.push(options[i]);
            }

            if (options[i].options) {
                this.assembleSelectedOptionMulti(key, options[i].options, selectedOptionMulti);
            }
        }
        return selectedOptionMulti;
    }

    componentDidMount = () => {
        this.assembleOptions(this.props);
    }

    render() {
        return (
            <>
                <Button color="link" className="float-right" onClick={this.onPostalSearchClick}>
                    {t('agenda.searchfreeslot:modal.subgroup.title')}
                </Button>

                <Select name={this.state.field.name} className="form-select"
                    isSearchable={true} simpleValue isMulti
                    closeMenuOnSelect={false} hideSelectedOptions={false}
                    value={this.state.selectedOptionMulti}
                    onChange={this.handleChangeSelectMulti}
                    options={this.state.options}
                    noOptionsMessage={this.noOptionsMessage}
                    placeholder={this.state.field.placeholder}
                    filterOption={this.filterOption}
                    onPostalSearchClick={this.onPostalSearchClick}
                    components={{
                        MenuList: MenuList,
                        // Option: CheckboxOption
                    }}
                />

                <ModalSubgroupSearch {...this._modalProps} />
            </>
        );
    }

    onPostalSearchClick = () => this._modal.show(true, this.state.selectedOptionMulti.map(o => o.value))
    noOptionsMessage = (input) => t('component.form:noOptions', { value: input.inputValue });
    filterOption = (label, value) => String(label.label).toLowerCase().startsWith(value.toLowerCase());

    handlePostalSearchConfirm = (data) => {
        let selected = [];
        data.forEach(id => {
            let filtered = this.state.options.filter(o => o.value === id)[0];
            if (filtered) selected.push(filtered);
        });
        this.handleChangeSelectMulti(selected);
    }
}

export default ChoicePostalField;

/*const CheckboxOption = ({ getStyles, isDisabled, isSelected, children, innerProps, ...rest }) => (
    <components.Option
        {...rest}
        isDisabled={isDisabled}
        isSelected={isSelected}
        getStyles={getStyles}
        onChange={rest.selectProps.onChange}
        innerProps={{
            ...innerProps,
            ...{
                alignItems: "center",
                backgroundColor: isSelected ? '#DEEBFF' : 'transparant',
                color: "inherit",
                display: "flex "
            }
        }}
    >
        <input type="checkbox" checked={isSelected} onChange={rest.selectProps.onChange} className="mr-1" />
        {children}
    </components.Option>
);*/

const MenuList = (props) => (
    <components.MenuList {...props}>
        <div className="text-center">
            <Button color="link" onClick={props.selectProps.onPostalSearchClick}>
                {t('agenda.searchfreeslot:modal.subgroup.title')}
            </Button>
        </div>
        {props.children}
    </components.MenuList>
);