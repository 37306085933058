import { t } from 'i18next';
import moment from "moment";
import { reverse } from 'named-urls';
import routes from '../../routing/routes';

export default class Task {
    id;

    status;
    priority;

    title;
    description;

    subcategory = {};
    patient = {};
    payment = {};

    data = [];
    assigned = [];
    watching = [];

    dateCreated;
    dateExpired;

    static transform = (data) => {
        let item = new Task();

        item.id = data.id;

        item.subcategory = data.subcategory;

        if (data.data.length) {
            item.data = data.data.map(d => {
                return {
                    label: d.label,
                    value: d.value,
                    type: d.type
                }
            })
        } else {
            if (data.title) item.data.push({ label: 'Titel', value: data.title });
            if (data.description) item.data.push({ label: 'Inhoud', value: data.description });
        }

        item.status = data.status;
        item.priority = data.priority;

        item.title = data.title;
        item.description = data.description;
        item.patient = data.patient;
        item.payment = data.payment;

        item.assigned = data.assigned;
        item.watching = data.watching;

        item.dateCreated = data.dateCreated ? moment(data.dateCreated) : null;
        item.dateExpired = data.dateExpired ? moment(data.dateExpired) : null;

        return item;
    }

    statusLabel = () => t('tools.task:statuses:text:' + this.status);
    priorityLabel = () => t('tools.task:priorities:text:' + this.priority);

    dateExpiredFormatted = () => this.dateExpired ? this.dateExpired.format('DD/MM/YYYY HH:mm') : '-';

    hasLinkedPatient = () => !!this.patient.id;
    patientLink = () => reverse(routes.patients.edit, { id: this.patient.id });
    editLink = () => reverse(routes.communication.tasks.edit, { id: this.id });

    hasSubcategory = () => !!this.subcategory.id;
}