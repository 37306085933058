import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Table, Input } from 'reactstrap';
import { ButtonActionCustom } from '../buttons';

class ModalMultipleCustomChoices extends React.Component {
    state = {
        modal: false,
        fieldIndex: null,
        choices: {}
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalBody>
                    <Table size="sm" striped bordered responsive>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <Input onChange={this.search} />
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            {Object.keys(this.state.choices).map(choice => (
                                <tr key={choice}>
                                    <td>{this.state.choices[choice]}</td>
                                    <td style={{ width: '1%' }} className='text-right'>
                                        <ButtonActionCustom size="xs" color="default" icon="arrow-right" tooltip={t('common:Select')} onClick={() => this.onConfirm(choice)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={this.onToggle}>{t('common:Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    search = (e) => {
        let searchTerm = e.target.value.toLowerCase();

        let choices = {};
        Object.keys(this.props.choices).forEach(choice => {
            if (
                !searchTerm ||
                choice.toLowerCase().indexOf(searchTerm) > -1 ||
                this.props.choices[choice].toLowerCase().indexOf(searchTerm) > -1
            ) {
                choices[choice] = this.props.choices[choice];
            }
        })

        this.setState({ choices: choices });
    }

    fire = (index, choices) => this.setState({ modal: true, choices: choices, fieldIndex: index });
    onToggle = () => this.setState({ modal: !this.state.modal });
    onConfirm = (choice) => this.setState({ modal: false }, () => this.props.onConfirm(this.state.fieldIndex, choice));
}

export default ModalMultipleCustomChoices;