import React from 'react';
import { Alert } from 'reactstrap';

const AlertIcon = props => (
    <Alert color={props.color} className={'d-md-flex align-items-center ' + props.className}
        isOpen={props.isOpen}
    >
        <span className={'mr-3 fa fa-' + props.icon}></span>
        {props.message}
    </Alert>
)

AlertIcon.defaults = {
    color: 'default',
    isOpen: true
}

export default AlertIcon;