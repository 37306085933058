import { API } from '../api';

const getByClient = () => {
    return API.get('client/group/group/get-by-client')
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.groups;
            }

            throw response.data;
        })
};

const getActiveGroupClient = () => {
    return API.get('client/group/group/get-active-group')
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.group;
            }

            return {};
        });
};

const getById = (id) => {
    return API.get('client/group/group/get-group', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.group;
            }

            return false;
        });
};

const getPhoneDevices = (id) => {
    return API.get('client/group/group/get-phone-devices', { groupId: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return [];
        });
};

const updateClientSort = (data) => {
    return API.post('client/group/group/update-sort', data)
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return false;
        });
};

export const ApiGroupService = {
    getByClient,
    getActiveGroupClient,
    updateClientSort,
    getById,
    getPhoneDevices
};